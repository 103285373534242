import { errorMessages } from "./errorsMessages";

export class NotificationObject {
  constructor({ show, message, severity, title }) {
    this.show = show;
    this.title = title;
    this.message = message;
    this.severity = severity;
  }
}

export class FormValidationError extends Error {
  errorMessages = {
    es_AR: "Error de validación",
    en_US: "Validation error",
  };

  constructor(message) {
    super(message);
    this.message = message;
  }

  get notificationObject() {
    return Object.keys(this.errorMessages).reduce((acc, locale) => {
      acc[locale] = new NotificationObject({ show: true, severity: "error", message: `${this.errorMessages[locale]}: ${this.message}` });
      return acc;
    }, {});
  }

  get notificationFormat() {
    return "modal";
  }
}

export class GeolocationError extends Error {
  errorMessages = {
    es_AR: "No se pudo obtener ubicación",
    en_US: "Geolocation error",
  };

  constructor(message) {
    super(message);
    this.message = message;
  }

  get notificationObject() {
    return Object.keys(this.errorMessages).reduce((acc, locale) => {
      acc[locale] = new NotificationObject({ show: true, severity: "error", message: `${this.errorMessages[locale]}: ${this.message}` });
      return acc;
    }, {});
  }

  get notificationFormat() {
    return "modal";
  }
}

export class ApplicationError extends Error {
  errorMessages = {
    es_AR: "Error de aplicación",
    en_US: "Aplication error",
  };

  constructor(message) {
    super(message);
    this.message = message;
  }

  get notificationObject() {
    return Object.keys(this.errorMessages).reduce((acc, locale) => {
      acc[locale] = new NotificationObject({ show: true, severity: "error", title: this.errorMessages[locale], message: this.message });
      return acc;
    }, {});
  }

  get notificationFormat() {
    return "modal";
  }
}

export class BuildReportError extends Error {
  errorMessages = {
    es_AR: "No se pudo construir el reporte",
    en_US: "Unable to build report",
  };

  constructor(message) {
    super(message);
    this.message = message;
  }

  get notificationObject() {
    return Object.keys(this.errorMessages).reduce((acc, locale) => {
      acc[locale] = new NotificationObject({ show: true, severity: "error", title: this.errorMessages[locale], message: this.message });
      return acc;
    }, {});
  }

  get notificationFormat() {
    return "modal";
  }
}

export class NoChangesError extends Error {
  get notificationObject() {
    return Object.keys(errorMessages.noChangesWarning).reduce((acc, locale) => {
      acc[locale] = new NotificationObject(errorMessages.noChangesWarning[locale]);
      return acc;
    }, {});
  }

  get notificationFormat() {
    return "modal";
  }
}
