//REACT
import { useContext, useState } from "react";

//Hook Form
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import refDataByKey from "../../../services/localRefData/refDataByKey.js";

//Context
import TaskContext from "../../../context/taskContext.js";

//MUI
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MenuItem, Select, InputLabel, FormControl, FormHelperText } from "@mui/material";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Components
import AppContext from "../../../context/appContext.js";
import { PhotoInput } from "../../PhotoInput/PhotoInput.jsx";
import CoordinatesInputField from "../../CoordinatesInputField/CoordinatesInputField.jsx";
import NumericInput from "../utils/NumericInput.jsx";

export default function TaskForm({ geolocation, position, handleCameraInput, watchPosition, stopWatch, editMode, plannedLocation, pictures, defaultValues }) {
  const { task } = useContext(TaskContext);
  const [expanded, setExpanded] = useState(false);
  const { setAlert } = useContext(AppContext);
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const horizons = useFieldArray({
    control,
    name: "horizons",
  });

  const getMenuOptions = (key, includeLong = true, lang = "es_AR") => {
    return refDataByKey(key).map((option, index) => {
      return (
        <MenuItem
          key={index}
          value={option.id}
        >
          {option[lang]}
          {includeLong ? " - " + option[`${lang}_long`] : ""}
        </MenuItem>
      );
    });
  };

  const confirmDelete = (index) => {
    setAlert({
      show: true,
      severity: "warning",
      message: "¿Desea borrar este registro?",
      action: () => horizons.remove(index),
      actionButtonTitle: "Confirmar",
      cancelButtonTitle: "Cancelar",
    });
  };

  return (
    <Stack spacing={2}>
      <Box
        pt={1}
        textAlign={"center"}
      >
        <Typography variant="body2">
          <b>Realizar pozo exploración con una pala, al menos 30cm. Una vez realizado, tome una fotografía colocando una regla como referencia.</b>
        </Typography>
      </Box>
      <Controller
        control={control}
        name="sampleLabel"
        defaultValue={task.key}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            disabled
            error={fieldState.error ? true : false}
            fullWidth
            name="sampleLabel"
            label="Rótulo Muestra"
          />
        )}
      />
      <Controller
        control={control}
        name="timestamp"
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            disabled
            error={fieldState.error ? true : false}
            name="timestamp"
            fullWidth
            label="Fecha y Hora"
            type={"datetime"}
          />
        )}
      />
      <CoordinatesInputField
        control={control}
        editMode={editMode}
        setValue={setValue}
        position={position}
        plannedLocation={plannedLocation}
        watch={watchPosition}
        stopWatch={stopWatch}
        geolocation={geolocation}
      />
      <Typography
        variant="caption"
        component="legend"
      >
        MUESTRA POR EXCAVACIÓN{" "}
      </Typography>
      <Controller
        control={control}
        name="sampleVolume"
        rules={{ required: "Campo requerido" }}
        defaultValue={0}
        render={({ field, fieldState }) => (
          <NumericInput
            field={field}
            fieldState={fieldState}
            label="Volumen de muestra"
            min={0}
            max={null}
            unit={"cm3"}
          />
        )}
      />

      <Stack
        display="flex"
        direction={"column"}
        spacing={1}
        width={"100%"}
        p={1}
      >
        <Button
          color="primary"
          variant="text"
          size="small"
          onClick={() => horizons.append(defaultValues.defaultHorizon)}
        >
          + AGREGAR HORIZONTE
        </Button>
      </Stack>

      {horizons.fields.map((item, index) => (
        <Accordion
          key={index}
          expanded={("horizons" in errors && errors.horizons[index]) || expanded === `horizons-${index}`}
          sx={{ width: "100%" }}
          onChange={handleExpand(`horizons-${index}`)}
        >
          <AccordionSummary
            sx={{ backgroundColor: "primary" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Stack
              display={"flex"}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Typography>Horizonte {index + 1}</Typography>
              {index !== 0 && (
                <IconButton
                  disabled={index === 0}
                  size="small"
                  color="error"
                  onClick={() => confirmDelete(index)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              display={"flex"}
              direction={"column"}
              key={item.id}
              spacing={1.5}
            >
              <Controller
                key={`horizons.${item.id}.code`}
                control={control}
                name={`horizons.${index}.code`}
                defaultValue={"ADC"}
                rules={{ required: "Campo requerido" }}
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="outlined"
                    error={fieldState.error ? true : false}
                  >
                    <InputLabel id="horizonCode-select-label">Código</InputLabel>
                    <Select
                      {...field}
                      MenuProps={{ sx: { zIndex: 10000 } }}
                      label="Código"
                      fullWidth
                      labelId="horizonCode-select-label"
                      onChange={(_, change) => {
                        field.onChange(change.props.value);
                      }}
                      value={field.value}
                    >
                      {getMenuOptions("horizonCodes", true, "es_AR")}
                    </Select>
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
              <Controller
                key={`horizons.${item.id}.depthStart`}
                control={control}
                name={`horizons.${index}.depthStart`}
                defaultValue={0}
                rules={{ required: "Campo requerido" }}
                render={({ field, fieldState }) => (
                  <NumericInput
                    field={field}
                    fieldState={fieldState}
                    label="Profundidad Comienzo"
                    min={0}
                    max={null}
                    unit={"cm"}
                  />
                )}
              />
              <Controller
                key={`horizons.${item.id}.depthEnd`}
                control={control}
                name={`horizons.${index}.depthEnd`}
                defaultValue={0}
                rules={{ required: "Campo requerido" }}
                render={({ field, fieldState }) => (
                  <NumericInput
                    field={field}
                    fieldState={fieldState}
                    label="Profundidad Fin"
                    min={0}
                    max={null}
                    unit={"cm"}
                  />
                )}
              />
              <Controller
                key={`horizons.${item.id}.soilTexture`}
                control={control}
                name={`horizons.${index}.soilTexture`}
                defaultValue={0}
                rules={{ required: "Campo requerido" }}
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="outlined"
                    error={fieldState.error ? true : false}
                  >
                    <InputLabel id="soilTexture-select-label">Textura</InputLabel>
                    <Select
                      {...field}
                      MenuProps={{ sx: { zIndex: 10000 } }}
                      label="Tipo de estructura"
                      name="soilTexture"
                      fullWidth
                      labelId="soilTexture-select-label"
                      onChange={(_, change) => {
                        field.onChange(change.props.value);
                      }}
                      value={field.value}
                    >
                      {getMenuOptions("soilTextures", true, "es_AR")}
                    </Select>
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}

      <Stack
        alignItems={"center"}
        spacing={1}
      >
        <Typography>Fotografía excavación</Typography>
        <PhotoInput
          width={150}
          height={150}
          handleCameraFile={handleCameraInput}
          picture={pictures.find((pic) => pic.key === task.key)}
          pictureKey={pictures.find((pic) => pic.key === task.key)?.key || task.key}
          pictureIndex={pictures.findIndex((pic) => pic.key === task.key) >= 0 ? pictures.findIndex((pic) => pic.key === task.key) : 0}
          pictureId={pictures.find((pic) => pic.key === task.key)?.id || null}
        />
      </Stack>
    </Stack>
  );
}
