import { useCallback, useEffect } from "react";
import { FormControl, InputLabel, OutlinedInput, FormHelperText } from "@mui/material";

//Helpers
import { roundNumber } from "../../services/helpers/roundNumber.js";
import { validateInputStringCoordinates } from "../../services/geolocationScripts/validateInputStringCoordinates.js";

//RHF
import { Controller } from "react-hook-form";

export default function CoordinatesInputField({ control, setValue, editMode, position, plannedLocation, geolocation, fieldName, readOnly, size = "small" }) {
  const determineLocationValue = useCallback(() => {
    if (editMode) {
      return null;
    }
    if (geolocation && position) {
      return [roundNumber(position.lat, 9), roundNumber(position.lng, 9)];
    }
    if (!geolocation) {
      return plannedLocation;
    }
    if (geolocation && !position) {
      return "";
    }
    if (!geolocation && position) {
      return [position.lat, position.lng];
    }
  }, [geolocation, position, editMode, plannedLocation]);

  useEffect(() => {
    let location = determineLocationValue();
    if (!editMode) {
      setValue("actualLocation", location);
    }
  }, [determineLocationValue, setValue, editMode]);

  return (
    <Controller
      control={control}
      name={fieldName ? fieldName : "actualLocation"}
      rules={{
        required: "Campo requerido",
        validate: validateInputStringCoordinates || "Coordenadas inválidas",
      }}
      render={({ field, fieldState }) => (
        <FormControl
          color={fieldState.error ? "error" : "primary"}
          fullWidth
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-coords">{"Coordenadas GPS"}</InputLabel>
          <OutlinedInput
            size={size}
            readOnly={true}
            id={"outlined-coords"}
            {...field}
            disabled={true}
            error={fieldState.error ? true : false}
            name="actualLocation"
            label={"Coordenadas GPS"}
            placeholder="Obteniendo ubicación..."
            onChange={(e) => field.onChange(e)}
          />
          <FormHelperText color={fieldState.error ? "error" : "inherit"}>
            {fieldState.error ? fieldState.error.message : "Ej. -32.123456,-61.123456"}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
