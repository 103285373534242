//REACT
import { useContext } from "react";
//Hook Form
import { Controller, useFormContext } from "react-hook-form";

//Context
import TaskContext from "../../../context/taskContext.js";

//MUI
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Slider from "@mui/material/Slider";

//Components
import { PhotoInput } from "../../PhotoInput/PhotoInput.jsx";

//Helpers
import CoordinatesInputField from "../../CoordinatesInputField/CoordinatesInputField.jsx";

export default function TaskForm({ geolocation, position, handleCameraInput, watchPosition, stopWatch, editMode, plannedLocation, pictures }) {
  const { task } = useContext(TaskContext);
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  return (
    <Stack
      width={"100%"}
      spacing={2}
    >
      <Controller
        rules={{ required: true }}
        control={control}
        name="timestamp"
        render={({ field }) => (
          <TextField
            {...field}
            disabled
            name="timestamp"
            fullWidth
            label="Fecha y Hora"
            type={"datetime"}
            error={"timestamp" in errors ? true : false}
            helperText={errors.timestamp?.type}
          />
        )}
      />
      <CoordinatesInputField
        control={control}
        editMode={editMode}
        setValue={setValue}
        position={position}
        plannedLocation={plannedLocation}
        watch={watchPosition}
        stopWatch={stopWatch}
        geolocation={geolocation}
      />
      <Controller
        control={control}
        name="dryMatterPerHectare"
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            helperText={errors.dryMatterPerHectare?.type}
            fullWidth
            name="dryMatterPerHectare"
            type={"number"}
            label="Biomasa (kg de materia seca x HA)"
            error={"dryMatterPerHectare" in errors ? true : false}
            InputProps={{
              endAdornment: <Typography variant="body1">Kg/Ha</Typography>,
              type: "number",
              min: 0,
              max: "null",
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="dryMatterPerAnimalDayM2"
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            helperText={errors.biomassPerAnimalDayM2?.type}
            fullWidth
            name="dryMatterPerAnimalDayM2"
            type={"number"}
            label="Biomasa (m2 para un día animal)"
            error={"dryMatterPerAnimalDayM2" in errors ? true : false}
            InputProps={{
              endAdornment: <Typography variant="body1">m2</Typography>,
              type: "number",
              min: 1,
              max: "600",
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
        )}
      />
      <Typography
        component="div"
        variant="caption"
      >
        Calidad Forraje
      </Typography>

      <Controller
        rules={{ required: true }}
        control={control}
        name={"forageQuality"}
        render={({ field }) => (
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Typography>{1}</Typography>
            <Slider
              {...field}
              min={1}
              max={5}
              valueLabelDisplay="auto"
              name={"forageQuality"}
              step={0.5}
              marks={true}
              onChange={(evt, value) => {
                evt.preventDefault();
                field.onChange(value);
              }}
            />
            <Typography>{5}</Typography>
          </Stack>
        )}
      />
      <FormControl
        variant="outlined"
        error={"forageUsePattern" in errors ? true : false}
      >
        <InputLabel id="pattern-select-label">Patrón de Uso</InputLabel>
        <Controller
          control={control}
          name="forageUsePattern"
          defaultValue={""}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              MenuProps={{ sx: { zIndex: 10000 } }}
              label="Patrón de Uso"
              name="forageUsePattern"
              fullWidth
              labelId="pattern-select-label"
              onChange={(_, change) => {
                field.onChange(change.props.value);
              }}
              value={field.value}
              error={"forageUsePattern" in errors ? true : false}
            >
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"SP"}>SP</MenuItem>
              <MenuItem value={"SD"}>SD</MenuItem>
              <MenuItem value={"DP"}>DP</MenuItem>
              <MenuItem value={"PP"}>PP</MenuItem>
            </Select>
          )}
        />
        <FormHelperText>{errors.forageUsePattern?.type}</FormHelperText>
      </FormControl>
      <FormControl
        variant="outlined"
        error={"forageIntensity" in errors ? true : false}
      >
        <InputLabel id="intensidad-select-label">Intensidad</InputLabel>
        <Controller
          control={control}
          name="forageIntensity"
          defaultValue={""}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              MenuProps={{ sx: { zIndex: 10000 } }}
              label="Intensidad"
              name="forageIntensity"
              fullWidth
              labelId="intensidad-select-label"
              onChange={(_, change) => {
                field.onChange(change.props.value);
              }}
              value={field.value}
            >
              <MenuItem value={"none"}>Nulo</MenuItem>
              <MenuItem value={"light"}>Liviano</MenuItem>
              <MenuItem value={"moderate"}>Moderado</MenuItem>
              <MenuItem value={"intense"}>Intenso</MenuItem>
            </Select>
          )}
        />
        <FormHelperText>{errors.forageIntensity?.type}</FormHelperText>
      </FormControl>
      <Stack
        spacing={3}
        component="fieldset"
        alignItems="center"
      >
        <Typography variant="body2">Foto Panorámica</Typography>
        <PhotoInput
          width={150}
          height={150}
          handleCameraFile={handleCameraInput}
          picture={pictures.find((pic) => pic.key === task.key + "-panoramic")}
          pictureKey={task.key + "-panoramic"}
          pictureIndex={
            pictures.findIndex((pic) => pic.key === task.key + "-panoramic") >= 0 ? pictures.findIndex((pic) => pic.key === task.key + "-panoramic") : 0
          }
          pictureId={pictures.find((pic) => pic.key === task.key + "-panoramic")?.id || null}
        />
        <Typography variant="body2">45º hacia el suelo</Typography>
        <PhotoInput
          width={150}
          height={150}
          handleCameraFile={handleCameraInput}
          picture={pictures.find((pic) => pic.key === task.key + "-45degrees")}
          pictureKey={task.key + "-45degrees"}
          pictureIndex={
            pictures.findIndex((pic) => pic.key === task.key + "-45degrees") >= 0 ? pictures.findIndex((pic) => pic.key === task.key + "-45degrees") : 1
          }
          pictureId={pictures.find((pic) => pic.key === task.key + "-45degrees")?.id || null}
        />
        <Typography variant="body2">90º hacia el suelo</Typography>
        <PhotoInput
          width={150}
          height={150}
          handleCameraFile={handleCameraInput}
          picture={pictures.find((pic) => pic.key === task.key + "-90degrees")}
          pictureKey={task.key + "-90degrees"}
          pictureIndex={
            pictures.findIndex((pic) => pic.key === task.key + "-90degrees") >= 0 ? pictures.findIndex((pic) => pic.key === task.key + "-90degrees") : 2
          }
          pictureId={pictures.find((pic) => pic.key === task.key + "-90degrees")?.id || null}
        />
      </Stack>
    </Stack>
  );
}
