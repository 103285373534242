//React
import { useEffect, useState, useContext, useCallback, useRef, useLayoutEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

//Context
import AppContext from "../../context/appContext.js";
import { TaskContextProvider } from "../../context/taskContext.js";

//Leafleti
import L from "leaflet";

//Db & Models
import * as localDb from "../../services/localDb";

//Services
import { taskConfigs } from "./taskConfigs.js";
import getDistanceUtm from "../../services/geolocationScripts/getDistanceUtm.js";
import checkActivityComplete from "../../services/helpers/checkActivityComplete.js";
import checkEventComplete from "../../services/helpers/checkEventComplete.js";
import getRandomPoint from "../../services/geolocationScripts/randomizers/getRandomPoint.js";
import completeTask from "../../services/helpers/completeTask.js";

//MUI
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

//Components
import NavDisplay from "../NavDisplay/NavDisplay";
import Navigator from "../Navigator/Navigator";
import TaskActions from "../TaskActions/TaskActions";
import Loading from "../Loading/Loading";
import TaskFormProvider from "../TaskForms/TaskFormProvider";
import GettingPositionModal from "../GettingPositionModal/GettingPositionModal.jsx";
import findClosestPointOnPerimeter from "../../services/geolocationScripts/findClosestPointOnPerimeter.js";
import { successMessages } from "../../models/success/successMessages.js";
import { ApplicationError, GeolocationError } from "../../models/errors/index.js";
import { Stack } from "@mui/material";
import getCentroid from "../../services/geolocationScripts/getCentroid.js";
import savePendingTask from "../../services/helpers/savePendingTask.js";
import { latLngToArray } from "../../services/geolocationScripts/latLngToArray.js";
import { emptyCoords } from "../../services/geolocationScripts/emptyCoords.js";
import zIndexLayers from "../../constants/zIndexLayers.js";

export default function TaskControl() {
  let { taskId } = useParams();
  let [searchParams] = useSearchParams();
  const watchId = useRef();
  const { setAppBarTitle, setShowAppBar, setNavBackTarget, setShowFilters, setAlert, currentUser, currentSettings, errorCatcher, currentProgramConfig } =
    useContext(AppContext);
  const navigate = useNavigate();

  const [task, setTask] = useState();
  const [withinRange, setWithinRange] = useState(false);
  const [rangeUnlocked, setRangeUnlocked] = useState(false);
  const [position, setPosition] = useState();
  const [heading, setHeading] = useState();
  const [distance, setDistance] = useState();
  const [bearing, setBearing] = useState();
  const [destination, setDestination] = useState();
  const [destinationName, setDestinationName] = useState();
  const [monitoringSite, setMonitoringSite] = useState();
  const [taskConfig, setTaskConfig] = useState();
  const [activity, setActivity] = useState();
  const [tempGrid, setTempGrid] = useState();
  const [taskDrawer, setTaskDrawer] = useState(false);
  const [randomizeArea, setRandomizeArea] = useState();
  const [taskForm, setTaskForm] = useState("primaryActionForm");
  const [formConfig, setFormConfig] = useState({});
  const [pointShuffled, setPointShuffled] = useState(false);
  const [geolocationObject, setGeolocationObject] = useState(null);
  const [gettingPosition, setGettingPosition] = useState(false);
  const [geolocation, setGeolocation] = useState(false);
  const [farm, setFarm] = useState(false);
  const [actionRangeMts, setActionRangeMts] = useState(null);
  const [completeTaskAfterSubmit, setCompleteTaskAfterSubmit] = useState(false);

  const updateAppBar = useCallback(
    (title) => {
      setShowFilters(false);
      setAppBarTitle(title);
      setNavBackTarget("/events/" + localStorage.getItem("currentEventId") + "?showList=true");
    },
    [setAppBarTitle, setNavBackTarget, setShowFilters]
  );

  const pointReset = () => {
    setMonitoringSite({ ...monitoringSite, backupLocation: null });
    setRandomizeArea(null);
    setPointShuffled(false);
    let dest;
    let destName;
    if (task.plannedLocation && task.plannedLocation.length) {
      dest = new L.LatLng(task.plannedLocation[0], task.plannedLocation[1]);
      destName = task.key;
    } else {
      dest = new L.LatLng(monitoringSite.actualLocation[0], monitoringSite.actualLocation[1]);
      destName = monitoringSite.name;
    }
    setDestination(dest);
    setDestinationName(destName);
  };

  const getBackupPoint = async () => {
    setPointShuffled(true);
    let newDestination = await getRandomPoint(monitoringSite, currentProgramConfig.randomizeVersion, setMonitoringSite);
    setDestination(newDestination.newPoint);
    setRandomizeArea(newDestination.polygon);
  };

  const handleTaskAction = async (actionIndex) => {
    try {
      let state = {
        activity: activity,
        position: position,
        geolocationObject: geolocationObject,
        destination: destination,
        destinationName: destinationName,
        monitoringSite: monitoringSite || null,
        task: task,
        setTask: setTask,
        setMonitoringSite: setMonitoringSite,
        setDestination: setDestination,
        setDestinationName: setDestinationName,
        setActivity: setActivity,
        setTaskDrawer: setTaskDrawer,
        setTempGrid,
        setPointShuffled,
        tempGrid,
        userName: currentUser.userName,
        taskComplete: taskComplete,
      };
      setTaskForm(taskConfig.actions[actionIndex].formName);
      setFormConfig(taskConfig.actions[actionIndex].formConfig);
      setCompleteTaskAfterSubmit(taskConfig.actions[actionIndex].completeTaskAfterSubmit);
      taskConfig.actions[actionIndex].action(state);
    } catch (error) {
      errorCatcher(new ApplicationError(error.message));
    }
  };

  const handleCancelNewPoint = () => {
    try {
      pointReset();
      setPointShuffled(false);
      setTaskDrawer(false);
    } catch (error) {
      errorCatcher(new ApplicationError(error.message));
    }
  };

  const handleCloseDrawer = () => {
    setPointShuffled(false);
    setTaskDrawer(false);
  };

  const taskComplete = async (taskData) => {
    try {
      //TaskComplete requested by taskFormProvider has taskData in submission.
      if (taskData) {
        await completeTask({
          activity,
          task,
          setTask,
          userName: currentUser.userName,
          data: taskData.data,
          pictures: taskData.pictures,
        });
      }
      checkActivityComplete(activity.id, currentUser.userName);
      checkEventComplete(task, currentUser.userName);
      setTaskDrawer(false);
      setAlert({ ...successMessages.taskComplete["es_AR"], action: () => navBack() });
    } catch (error) {
      errorCatcher(new ApplicationError(error.message));
    }
  };

  const taskPending = async (taskData) => {
    try {
      await savePendingTask(task, setTask, currentUser.userName, taskData?.data, taskData?.pictures);
      setTaskDrawer(false);
      setAlert({ ...successMessages.taskPending["es_AR"], action: () => navBack() });
    } catch (error) {
      errorCatcher(new ApplicationError(error.message));
    }
  };

  const navBack = useCallback(() => {
    setShowAppBar(true);
    navigate(`/events/${task.monitoringEventId}?showList=true`, { replace: true });
  }, [setShowAppBar, navigate, task]);

  const geoSuccess = useCallback((newPosition) => {
    let posLatLng = new L.LatLng(newPosition.coords.latitude, newPosition.coords.longitude);
    setGeolocationObject((prev) => ({
      latitude: newPosition.coords.latitude,
      longitude: newPosition.coords.longitude,
      accuracy: newPosition.coords.accuracy,
      altitude: newPosition.coords.altitude,
      altitudeAccuracy: newPosition.coords.altitudeAccuracy,
    }));
    setPosition(posLatLng);
    setHeading(newPosition.coords.heading);
    setGettingPosition(false);
  }, []);

  const geoError = useCallback(
    (error) => {
      setGettingPosition(false);
      setGeolocation(false);
      errorCatcher(new GeolocationError(error.message));
    },
    [errorCatcher]
  );

  const updateWithinRange = useCallback(
    (value) => {
      setWithinRange(value);
    },
    [setWithinRange]
  );

  const watch = useCallback(() => {
    setGeolocation(true);
    setGettingPosition(true);
    const options = {
      enableHighAccuracy: true,
      timeout: 60000,
      maximumAge: 500,
    };
    navigator.geolocation.clearWatch(watchId.current);
    watchId.current = navigator.geolocation.watchPosition(geoSuccess, geoError, options);
  }, [geoSuccess, geoError]);

  const stopWatch = () => {
    navigator.geolocation.clearWatch(watchId.current);
    setGeolocation(false);
    setGettingPosition(false);
  };

  useEffect(() => {
    async function getTaskFromDb() {
      try {
        let currentSiteId = localStorage.getItem("currentSiteId");
        let monitoringSite = await localDb.getOne("sites", currentSiteId);
        let task = await localDb.getOne("tasks", taskId);
        let activity = await localDb.getOne("activities", task.monitoringActivityId);
        let event = await localDb.getOne("events", activity.monitoringEventId);
        let farm = await localDb.getOne("farms", event.farmId);

        let dest;
        let destName = task.key;

        if (task.actualLocation && task.actualLocation.length) {
          let destArray = latLngToArray(task.actualLocation);
          dest = new L.LatLng(destArray[0], destArray[1]);
          destName = task.key;
        } else if (task.plannedLocation && task.plannedLocation.length) {
          let destArray = latLngToArray(task.plannedLocation);
          dest = new L.LatLng(destArray[0], destArray[1]);
          destName = task.key;
        } else if (activity.monitoringSiteId) {
          let destArray = latLngToArray(monitoringSite.actualLocation);
          dest = new L.LatLng(destArray[0], destArray[1]);
          destName = monitoringSite.name;
        }

        setActivity(activity);
        setTaskConfig(taskConfigs[task.type]);
        setMonitoringSite(monitoringSite);
        setDestination(dest);
        setDestinationName(destName);
        setTask(task);
        setFarm(farm);
        updateAppBar(task.key);
        setShowAppBar(false);
      } catch (error) {
        errorCatcher(new ApplicationError(error.message));
      }
    }
    getTaskFromDb();
  }, [taskId, updateAppBar, setShowAppBar, errorCatcher]);

  useEffect(() => {
    if (!searchParams.get("edit")) watch();
    return () => {
      stopWatch();
    };
  }, [watch, searchParams, task]);

  useLayoutEffect(() => {
    if (task) {
      let actionRangeMts = taskConfig.actionRangeMts; //null=Libre o //INT mts ej 3

      //custom config override task config if confirmSite
      if (task.type === "confirmSite") {
        actionRangeMts = currentSettings?.confirmSiteRangeMts || taskConfig.actionRangeMts;
      }

      setActionRangeMts(actionRangeMts);

      if (actionRangeMts) {
        if (distance < actionRangeMts && !withinRange) {
          updateWithinRange(true);
        }
        if (distance > actionRangeMts && withinRange) {
          updateWithinRange(false);
        }
      } else {
        updateWithinRange(true);
      }
    }
  }, [distance, withinRange, updateWithinRange, task, setActionRangeMts, currentSettings, geolocation, taskConfig]);

  useLayoutEffect(() => {
    if (position && destination) {
      let result = getDistanceUtm(position, destination);
      setDistance(result.distance);
      setBearing(result.bearing);
    }
  }, [position, destination]);

  useEffect(() => {
    //Monitoring Workflow ID 4 =  FBC Perimeter Validation. Set destination to closes point on perimeter.
    if (activity && activity.monitoringWorkflowId === 4) {
      if (farm && position && task && emptyCoords(task.actualLocation)) {
        let sourcePosition = position || getCentroid(farm.toGeoJSON);
        const dest = findClosestPointOnPerimeter([sourcePosition.lng, sourcePosition.lat], farm.toGeoJSON);
        setDestination(dest);
      }
    }
  }, [position, activity, farm, task]);

  return (
    <Stack
      flex
      direction="column"
      sx={{ overflow: "hidden", height: "100vh", width: "100vw" }}
    >
      {task && activity ? (
        <TaskContextProvider value={{ task, setTask }}>
          <NavDisplay
            destination={destination}
            destinationName={destinationName}
            distance={distance}
            bearing={bearing}
            heading={heading}
            withinRange={withinRange}
            geolocation={geolocation}
            actionRangeMts={actionRangeMts}
            navBack={navBack}
            taskName={task.description}
            monitoringSite={monitoringSite}
            rangeUnlocked={rangeUnlocked}
          />
          <Navigator
            watch={() => watch()}
            stopWatch={() => stopWatch()}
            position={position}
            bearing={bearing}
            heading={heading}
            withinRange={withinRange}
            destination={destination}
            randomizeArea={randomizeArea}
            destinationName={destinationName}
            activityGrid={activity.activityGrid}
            tempGrid={tempGrid}
            getBackupPoint={getBackupPoint}
            pointShuffled={pointShuffled}
            handleCancel={handleCancelNewPoint}
            setPointShuffled={setPointShuffled}
            allowShuffle={task.type === "confirmSite"}
            taskKey={task.gridPositionKey}
            editMode={searchParams.get("edit")}
            perimeter={farm.toGeoJSON}
            geolocation={geolocation}
            allowRangeOverride={taskConfig?.allowRangeOverride || false}
            rangeUnlocked={rangeUnlocked}
            setRangeUnlocked={setRangeUnlocked}
          />
          <Drawer
            keepMounted={false}
            anchor="bottom"
            open={taskDrawer}
            sx={{ zIndex: zIndexLayers.critical, overflow: "visible" }}
            PaperProps={{
              sx: { zIndex: zIndexLayers.critical, overflow: "visible", height: `${formConfig?.fullHeight ? "100vh" : "65vh"}` },
            }}
          >
            {" "}
            {taskConfig && (task.monitoringSiteId ? monitoringSite : true) && (
              <TaskFormProvider
                formConfig={formConfig}
                completeTaskAfterSubmit={completeTaskAfterSubmit}
                taskFormName={taskForm}
                monitoringSite={monitoringSite}
                setMonitoringSite={setMonitoringSite}
                taskComplete={taskComplete}
                taskPending={taskPending}
                handleClose={() => handleCloseDrawer()}
                handleCancel={() => handleCancelNewPoint()}
                setTaskDrawer={setTaskDrawer}
                position={position}
                geolocation={geolocation}
                geolocationObject={geolocationObject}
                plannedLocation={task.plannedLocation ? task.plannedLocation : monitoringSite ? monitoringSite.plannedLocation : null}
                destination={destination}
                editMode={/true/i.test(searchParams.get("edit"))}
                watch={() => watch()}
                stopWatch={() => stopWatch()}
                setTempGrid={setTempGrid}
                activity={activity}
                setPointShuffled={setPointShuffled}
                pointShuffled={pointShuffled}
              />
            )}
          </Drawer>
          <Box
            id="nav-control"
            pb={4}
            position="absolute"
            bottom={"0%"}
            zIndex={zIndexLayers.mapControls}
            flex
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
          >
            {!gettingPosition && (
              <TaskActions
                actionHandler={handleTaskAction}
                withinRange={false || withinRange}
                activityGrid={tempGrid}
                pointShuffled={pointShuffled}
                taskActions={taskConfig.actions}
                editMode={searchParams.get("edit")}
                taskStarted={task.taskStatusId !== 0}
                geolocation={geolocation}
                rangeUnlocked={rangeUnlocked}
              />
            )}
          </Box>
          {gettingPosition && (
            <GettingPositionModal
              show={gettingPosition}
              geolocationObject={geolocationObject}
              handleClose={() => setGettingPosition(false)}
              cancelAction={() => stopWatch()}
            />
          )}
        </TaskContextProvider>
      ) : (
        <Loading />
      )}
    </Stack>
  );
}
