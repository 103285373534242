//React
import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

//Icons
import CameraAlt from "@mui/icons-material/CameraAlt";
import PhotoLibrary from "@mui/icons-material/PhotoLibrary";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";

import compressImage from "../../services/helpers/compressImage.js";
import imageCompression from "browser-image-compression";
import zIndexLayers from "../../constants/zIndexLayers.js";

export function PhotoInput({ picture, pictureKey, pictureId, height, width, pictureIndex, handleCameraFile, required }) {
  const [source, setSource] = useState();
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCapture = async (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        setIsLoading(true);
        try {
          const file = target.files[0];
          let compressedFile = await compressImage(file);
          let dataUrl = await imageCompression.getDataUrlFromFile(compressedFile);
          let fn = `${pictureKey}${file.name.slice(file.name.indexOf("."))}`;
          let key = pictureKey;
          let exifData = file.exifData || null;
          setSource(dataUrl);
          handleCameraFile(dataUrl, pictureIndex, fn, key, pictureId, exifData);
        } catch (error) {
          console.error("Error al procesar la imagen:", error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const getBorderColor = () => {
    if (required && !source) {
      return "2px dashed red";
    } else {
      return "2px dashed grey";
    }
  };

  const handleOpenFullScreen = () => {
    if (source) {
      setOpenFullScreen(true);
    }
  };

  const handleCloseFullScreen = () => {
    setOpenFullScreen(false);
  };

  const handleDownloadImage = () => {
    if (!source) return;

    const link = document.createElement("a");
    link.href = source;

    const fileName = `${pictureKey || "photo"}-${new Date().getTime()}.jpg`;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  useEffect(() => {
    if (picture?.data) {
      setSource(picture.data);
    } else {
      if (picture?.link) {
        setSource(picture.link);
      }
    }
  }, [picture]);

  return (
    <Box
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
    >
      {source ? (
        <Box
          border={getBorderColor()}
          borderRadius={"10px 10px 10px 10px"}
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          height={height || "100px"}
          width={width || "100px"}
          sx={{
            overflow: "hidden",
            cursor: "pointer",
            position: "relative",
          }}
          onClick={handleOpenFullScreen}
        >
          {isLoading ? (
            <Box
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              bgcolor="rgba(0, 0, 0, 0.5)"
              zIndex={zIndexLayers.loading}
            >
              <CircularProgress
                size={24}
                sx={{ color: "white" }}
              />
            </Box>
          ) : null}
          <img
            height={height}
            style={{
              borderRadius: "10px 10px 10px 10px",
              display: "block",
              maxWidth: "100%",
            }}
            alt={pictureKey + "-photo"}
            src={source}
          />
        </Box>
      ) : (
        <Box
          border={getBorderColor()}
          borderRadius={"10px 10px 10px 10px"}
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          height={height || "100px"}
          width={width || "100px"}
          sx={{
            overflow: "hidden",
            position: "relative",
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <label htmlFor={pictureKey}>
              <CameraAlt sx={{ color: required ? "red" : "black", cursor: "pointer" }}></CameraAlt>
            </label>
          )}
        </Box>
      )}

      <input
        name={pictureKey}
        style={{ display: "none" }}
        accept="image/*"
        id={pictureKey}
        type="file"
        onChange={(e) => handleCapture(e.target)}
      />

      <Dialog
        fullScreen={true}
        open={openFullScreen}
        onClose={handleCloseFullScreen}
        PaperProps={{
          style: {
            zIndex: zIndexLayers.critical,
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          },
        }}
        sx={{
          zIndex: zIndexLayers.critical,
          position: "fixed",
        }}
      >
        <DialogContent
          sx={{
            padding: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseFullScreen}
            sx={{
              position: "absolute",
              right: 16,
              top: 16,
              zIndex: zIndexLayers.critical,
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              maxHeight: "calc(100% - 70px)",
              padding: { xs: 1, sm: 2, md: 4 },
            }}
          >
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
              alt={pictureKey + "-photo-fullscreen"}
              src={source}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            padding: { xs: 0.5, sm: 1 },
            position: "absolute",
            bottom: 10,
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          <Stack
            direction="row"
            spacing={{ xs: 1, sm: 2 }}
            justifyContent="center"
            py={1}
          >
            <IconButton
              color="primary"
              aria-label="Descargar foto"
              onClick={handleDownloadImage}
              size="medium"
              title="Descargar"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                p: { xs: 1.5, sm: 2 },
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.4)",
                },
              }}
            >
              <DownloadIcon fontSize="medium" />
            </IconButton>

            <label htmlFor={pictureKey + "-modal"}>
              <IconButton
                color="primary"
                aria-label="Usar cámara"
                component="span"
                size="medium"
                title="Cámara"
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  p: { xs: 1.5, sm: 2 },
                  color: "white",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                  },
                }}
              >
                <CameraAlt fontSize="medium" />
              </IconButton>
            </label>

            <label htmlFor={pictureKey + "-gallery"}>
              <IconButton
                color="primary"
                aria-label="Usar galería"
                component="span"
                size="medium"
                title="Galería"
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  p: { xs: 1.5, sm: 2 },
                  color: "white",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                  },
                }}
              >
                <PhotoLibrary fontSize="medium" />
              </IconButton>
            </label>

            <input
              name={pictureKey + "-modal"}
              style={{ display: "none" }}
              accept="image/*"
              id={pictureKey + "-modal"}
              type="file"
              capture="environment"
              onChange={(e) => {
                handleCapture(e.target);
                handleCloseFullScreen();
              }}
            />

            <input
              name={pictureKey + "-gallery"}
              style={{ display: "none" }}
              accept="image/*"
              id={pictureKey + "-gallery"}
              type="file"
              onChange={(e) => {
                handleCapture(e.target);
                handleCloseFullScreen();
              }}
            />
          </Stack>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
