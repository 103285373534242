//Hook Form
import { Controller, useFormContext } from "react-hook-form";

//MUI
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//Components
import CoordinatesInputField from "../../CoordinatesInputField/CoordinatesInputField.jsx";
import NumericInput from "../utils/NumericInput.jsx";
import SpeciesFieldArrayInput from "../common/SpeciesFieldArrayInput.jsx";

export default function TaskForm({ geolocation, position, handleCameraInput, watchPosition, stopWatch, editMode, plannedLocation }) {
  const { control, setValue } = useFormContext();

  return (
    <Stack spacing={2}>
      <CoordinatesInputField
        control={control}
        editMode={editMode}
        setValue={setValue}
        position={position}
        plannedLocation={plannedLocation}
        watch={watchPosition}
        stopWatch={stopWatch}
        geolocation={geolocation}
      />
      <Stack
        component="fieldset"
        display="flex"
        direction={"column"}
        spacing={2}
      >
        <Box
          pt={1}
          textAlign={"center"}
        >
          <Typography variant="h6">
            <b>Lectura área flexible</b>
          </Typography>
          <Typography>Ingrese cada especie, y cantidad de individuos observados dentro de la faja comprendida 1 mts de cada lado de la transecta #2</Typography>
        </Box>
        <SpeciesFieldArrayInput
          fieldArrayName="speciesFlexibleArea"
          withFunctionalGroup={true}
        >
          {(item, index) => (
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={1}
            >
              <Controller
                rules={{ required: true }}
                key={`speciesFlexibleArea.${item.id}.amountOfIndividuals`}
                control={control}
                name={`speciesFlexibleArea.${index}.amountOfIndividuals`}
                render={({ field, fieldState }) => (
                  <NumericInput
                    field={field}
                    fieldState={fieldState}
                    label="Cantidad"
                    min={0}
                    max={null}
                  />
                )}
              />
              <Controller
                rules={{ required: true }}
                key={`speciesFlexibleArea.${item.id}.areaCovered`}
                control={control}
                name={`speciesFlexibleArea.${index}.areaCovered`}
                render={({ field, fieldState }) => (
                  <NumericInput
                    field={field}
                    fieldState={fieldState}
                    label="Area cubierta"
                    min={0}
                    max={null}
                    unit={"m2"}
                  />
                )}
              />
            </Stack>
          )}
        </SpeciesFieldArrayInput>
      </Stack>
    </Stack>
  );
}
