import { v4 as uuid } from "uuid";
import moment from "moment";
import Dexie from "dexie";
import { appStore } from "../lfStorage/lfStorage";
import download from "downloadjs";
import { exportDB, peakImportFile, importDB } from "dexie-export-import";
import { v10 } from "./versions.js";

export class LocalDbError extends Error {
  constructor(message, code) {
    super();
    this.message = message;
    this.code = code;
  }
}
export class LocalDbAuthenticationError extends LocalDbError {
  constructor(message, code) {
    super();
    this.message = message;
    this.code = code;
  }
}

function handleLocalDbError(error) {
  console.error(error);
  if (error instanceof LocalDbAuthenticationError) {
    throw error;
  }
  throw new LocalDbError(`Local DB error ${error.message}`);
}

async function openDb() {
  let currentUser = await appStore.get("currentUser");
  if (!currentUser) {
    handleLocalDbError(new LocalDbAuthenticationError(`Login needed`, 401));
  }
  let db = new Dexie(currentUser.userName);
  db.version(v10.versionNumber)
    .stores(v10.stores)
    .upgrade(async (trx) => {
      await Promise.all(v10.migrations.map((migration) => migration({ transaction: trx })));
    });
  return db;
}

export async function logChange(entity, entityId, updatedFields, type, db) {
  try {
    await db.changelog.put({
      id: uuid(),
      monitoringEventId: localStorage.getItem("currentEventId"),
      entity: entity,
      entityId: entityId,
      type: type,
      changedFields: updatedFields,
      syncStatus: "not-synced",
      lastSyncDate: null,
      timestamp: moment().format("x"),
    });
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function getPendingChanges(monitoringEventId) {
  try {
    let db = await openDb();
    return await db.changelog
      .where("syncStatus")
      .anyOf(["not-synced", "failed"])
      .and((item) => item.monitoringEventId === monitoringEventId)
      .sortBy("timestamp");
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function removeChanges(entity, entityId) {
  try {
    let db = await openDb();
    await db.changelog.where("entity").equals(entity).and("entityId").equals(entityId).delete();
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function remove(table, id, log = true, dbObject) {
  try {
    const db = dbObject || (await openDb());
    await db[table].delete(id);
    await removeChanges(table, id);
    if (log) await logChange(table, id, [], "delete", db);
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function update(table, id, data, log = true, dbObject) {
  try {
    const db = dbObject || (await openDb());
    await db[table].update(id, { ...data, _rev: uuid() });
    if (log) await logChange(table, id, Object.keys(data), "update", db);
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function put(table, id, data, log = true, dbObject) {
  try {
    const db = dbObject || (await openDb());
    await db[table].put(data, id);
    if (log) await logChange(table, id, Object.keys(data), "put", db);
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function upsert(table, id, data, log = true, dbObject) {
  try {
    const db = dbObject || (await openDb());
    const exists = await db[table].get(id);
    if (exists) {
      await db[table].update(data, id);
      if (log) await logChange(table, id, Object.keys(data), "update", db);
    } else {
      await db[table].put(data, id);
      if (log) await logChange(table, id, Object.keys(data), "put", db);
    }
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function bulkPut(table, data, log = true, dbObject) {
  try {
    const db = dbObject || (await openDb());
    await db.transaction("rw", [table, "changelog"], async () => {
      await db[table].bulkPut(data);
      if (log) {
        await Promise.all(
          data.map((record) => {
            return logChange(table, record.id, Object.keys(record), "put", db);
          })
        );
      }
    });
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function getOne(table, id, dbObject) {
  try {
    const db = dbObject || (await openDb());
    let data = await db[table].get(id);
    return data;
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function getMany(table, dbObject) {
  try {
    const db = dbObject || (await openDb());
    return await db[table];
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function exportDb({ downloadFile, progressCallback }) {
  try {
    let db = await openDb();
    const blob = await exportDB(db, { prettyJson: true, progressCallback });
    if (downloadFile) {
      return download(blob, "dexie-export.json", "application/json");
    } else {
      return blob;
    }
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function importDb({ fileData, options }) {
  try {
    return await importDB(fileData, {
      ...options,
      clearTablesBeforeImport: true,
      overwriteValues: true,
      acceptVersionDiff: true,
    });
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function peakInfo({ fileData }) {
  try {
    return await peakImportFile(fileData);
  } catch (e) {
    handleLocalDbError(e);
  }
}

export async function getDb() {
  return await openDb();
}
