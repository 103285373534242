import { useForm, FormProvider } from "react-hook-form";
import { taskForms } from "./index.js";
import { v4 as UUIDV4 } from "uuid";
import { useContext, useState, useEffect, useCallback } from "react";
import moment from "moment";
import * as localDb from "../../services/localDb/index.js";
import * as taskActions from "../../services/taskActions/index.js";

//Context
import TaskContext from "../../context/taskContext.js";
import AppContext from "../../context/appContext.js";
import { Box, Button, ButtonGroup, Stack } from "@mui/material";
import DrawerHeaderBar from "../DrawerHeaderBar/DrawerHeaderBar.jsx";
import GpsIndicator from "../GpsIndicator/GpsIndicator.jsx";
import { ApplicationError } from "../../models/errors/index.js";
import { latLngToArray } from "../../services/geolocationScripts/latLngToArray.js";
import { errorMessages } from "../../models/errors/errorsMessages.js";
import TaskFormContext from "../../context/TaskFormContext.js";
import zIndexLayers from "../../constants/zIndexLayers.js";

export default function TaskFormProvider({
  monitoringSite,
  taskComplete,
  taskPending,
  handleClose,
  handleCancel,
  position,
  geolocation,
  geolocationObject,
  plannedLocation,
  destination,
  editMode,
  watch,
  stopWatch,
  setTempGrid,
  activity,
  formConfig,
  taskFormName,
  setTaskDrawer,
  setMonitoringSite,
  rangeUnlocked,
  setPointShuffled,
  pointShuffled,
  completeTaskAfterSubmit,
}) {
  const FormToRender = taskForms[taskFormName];
  const { errorCatcher, currentUser, setAlert, locale, currentEvent } = useContext(AppContext);
  const { task, setTask } = useContext(TaskContext);
  const [pictures, setPictures] = useState([]);
  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    defaultValues:
      task.taskStatusId === 0
        ? {
            sampleLabel: task.key,
            timestamp: moment().format("YYYY-MM-DD HH:mm:ssZ"),
            plannedLocation: task.plannedLocation || monitoringSite?.actualLocation,
            actualLocation: geolocation && position ? `${position.lat.toFixed(9)}, ${position.lng.toFixed(9)}` : "",
            ...(formConfig?.defaultValues || null),
            deviceLocationData: geolocationObject,
          }
        : {
            ...task.dataPayload,
            actualLocation: latLngToArray(task.actualLocation),
            deviceLocationData: task.deviceLocationData,
          },
  });

  const runCustomCompleteAction = useCallback(
    async (data) => {
      await taskActions[formConfig.formSubmitAction]({
        position,
        geolocationObject,
        activity,
        setTempGrid,
        task,
        data,
        pictures,
        currentUser,
        currentEvent,
        monitoringSite,
        setTask,
        setMonitoringSite,
        setAlert,
        setPointShuffled,
      });
    },
    [
      formConfig.formSubmitAction,
      position,
      geolocationObject,
      activity,
      setTempGrid,
      task,
      pictures,
      currentUser,
      currentEvent,
      monitoringSite,
      setTask,
      setMonitoringSite,
      setAlert,
      setPointShuffled,
    ]
  );

  const handleTaskComplete = useCallback(
    async (data) => {
      try {
        if (!pictures || pictures.length < formConfig?.requiredPictures) {
          throw new Error(`Faltan fotografías requeridas.`);
        }
        if (formConfig.formSubmitAction) {
          await taskActions(data);
          setTaskDrawer(false);
        }
        if (completeTaskAfterSubmit) {
          taskComplete({ data: data, pictures: pictures });
        }
      } catch (error) {
        errorCatcher(new ApplicationError(error.message));
      }
    },
    [pictures, errorCatcher, taskComplete, completeTaskAfterSubmit, setTaskDrawer, runCustomCompleteAction, formConfig]
  );

  const handleTaskPending = async (errors, event) => {
    try {
      if (completeTaskAfterSubmit) {
        let alertConfig = errorMessages.saveWithErrors(errors)[locale];
        setAlert({
          ...alertConfig,
          action: async () => {
            let data = formMethods.getValues();
            if (completeTaskAfterSubmit) {
              taskPending({ data: data, pictures: pictures });
            }
          },
          actionButtonTitle: "Guardar",
          cancelButtonTitle: "volver",
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      errorCatcher(new ApplicationError(error.message));
    }
  };

  const handleCameraInput = async (file, index, name, key, id, exifData) => {
    if (file === null) {
      setPictures(pictures.filter((picture) => picture.id !== id));
      return;
    }
    setPictures((prev) => {
      const newPictures = [...prev];
      newPictures[index] = {
        id: id,
        data: file,
        name: name,
        key: key,
        _rev: UUIDV4(),
        exifData: exifData,
      };
      return newPictures;
    });
  };

  useEffect(() => {
    async function getTaskPictures() {
      let pictures = await localDb
        .getMany("pictures")
        .then((result) => result.where({ entity: "task", entityId: task.id }).toArray())
        .catch((e) => console.error("Unable to get pictures", e.message));
      if (pictures && pictures.length) {
        setPictures(pictures);
      }
    }
    getTaskPictures();
  }, [task]);

  useEffect(() => {
    if (!pointShuffled && !editMode && geolocation && position) {
      formMethods.setValue("actualLocation", `${position.lat.toFixed(9)}, ${position.lng.toFixed(9)}`);
      formMethods.setValue("deviceLocationData", geolocationObject);
    }
  }, [geolocation, position, editMode, pointShuffled, geolocationObject, formMethods]);

  return (
    <Stack
      component={"div"}
      height={"100%"}
      overflow={"auto"}
      direction={"column"}
      width={"100%"}
    >
      <DrawerHeaderBar
        title={task.key}
        handleClose={handleClose}
        marquee={true}
      />
      <GpsIndicator
        editMode={editMode}
        gpsOn={geolocation}
      />
      <TaskFormContext.Provider
        value={{
          pictures,
          setPictures,
          handleCameraFile: handleCameraInput,
        }}
      >
        <FormProvider {...formMethods}>
          <Stack
            component="form"
            onSubmit={formMethods.handleSubmit(handleTaskComplete, handleTaskPending)}
            spacing={2}
            justifyContent={"space-between"}
            height={"100%"}
          >
            <Box p={2}>
              <FormToRender
                activity={activity}
                setTempGrid={setTempGrid}
                handleCameraInput={handleCameraInput}
                handleTaskComplete={handleTaskComplete}
                handleCancel={handleCancel}
                handleClose={handleClose}
                editMode={editMode}
                pictures={pictures}
                setPictures={setPictures}
                watchPosition={watch}
                stopWatch={stopWatch}
                plannedLocation={plannedLocation}
                geolocation={geolocation}
                position={position}
                monitoringSite={monitoringSite}
                defaultValues={formConfig?.defaultValues || null}
                destination={destination}
                rangeUnlocked={rangeUnlocked}
              />
            </Box>

            <Stack
              sx={{ boxShadow: 3 }}
              backgroundColor="white"
              flex
              p={2}
              pb={3.5}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent="center"
              flexWrap={"nowrap"}
              justifySelf={"end"}
              position={"sticky"}
              bottom="0"
              flexGrow={0}
              zIndex={zIndexLayers.maximum}
            >
              <ButtonGroup
                fullWidth
                variant="contained"
                size="large"
              >
                <Button
                  color="primary"
                  type="submit"
                >
                  {formConfig.submitButtonTitle || "GUARDAR"}
                </Button>
                <Button
                  color="secondary"
                  onClick={() => handleClose()}
                >
                  CANCELAR
                </Button>
              </ButtonGroup>
            </Stack>
          </Stack>
        </FormProvider>
      </TaskFormContext.Provider>
    </Stack>
  );
}
