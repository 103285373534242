import { confirmMonitoringSite } from '../../services/taskActions/confirmMonitoringSite.js';
import { generateRelativeGrid } from "../../services/taskActions/generateRelativeGrid.js";
import { startGenericTask } from '../../services/taskActions/startGenericTask.js';
import { saveRelativeGrid } from "../../services/taskActions/saveRelativeGrid.js";
import {cancelPointShuffle} from '../../services/taskActions/cancelPointShuffle.js'

const generateGridDefaultActions = [
    {
        action: generateRelativeGrid,
        color: 'primary',
        name: 'Proyectar aquí',
        dialog: null,
        formName: null,
        visibleDeps: { },
        enableDeps: { is: { and: ['withinRange', 'geolocation']}},
        needConfirmation: false,
        completeTaskAfterSubmit: false
    },
    {
        action: saveRelativeGrid,
        color: 'success',
        name: 'Guardar',
        dialog: 'Se asignaran las coordenadas para los puntos de la grilla.',
        formName: null,
        visibleDeps: { is: { or: ['activityGrid']} },
        enableDeps: { is: { or : ['activityGrid']} },
        needConfirmation: true,
        completeTaskAfterSubmit: false
    },
]

export const taskConfigs = {
    confirmSite: {
        actionRangeMts: 10,
        allowRangeOverride: true,
        actions: [
            {
                action: confirmMonitoringSite,
                color: 'primary',
                name: 'Confirmar',
                dialog: 'Confirmará la accesibilidad al sitio de monitoreo para todos los flujos (SOC, STM, LTM)',
                formName: null,
                visibleDeps: { not: { or: ['pointShuffled']}},
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: true,
                completeTaskAfterSubmit: true,
            },
            {
                action: startGenericTask,
                color: 'primary',
                name: 'GUARDAR NUEVO SITIO',
                dialog: 'Cambiará las coordenadas planeadas para el sitio de monitoreo para todos los flujos (SOC, STM, LTM).',
                formName: 'RejectSite',
                visibleDeps: { is: {or: ['pointShuffled']} },
                enableDeps: { is: { or: ['pointShuffled']} },
                needConfirmation: true,
                completeTaskAfterSubmit: false,
                formConfig: {
                    fullHeight: false,
                    formSubmitAction: 'rejectSite',
                    requiredPictures: 0
                }
            },
            {
                action: cancelPointShuffle,
                color: 'primary',
                name: 'CANCELAR',
                variant: 'outlined',
                dialog: null,
                formName: null,
                visibleDeps: { is: {or: ['pointShuffled']} },
                enableDeps: { is: { or: ['pointShuffled']} },
                needConfirmation: false,
                completeTaskAfterSubmit: false,
            }
        ]
    },
    socGenerateGrid: {
        actionRangeMts: 20,
        allowRangeOverride: true,
        actions: generateGridDefaultActions
    },
    socGridSampling: {
        actionRangeMts: 10,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                dialog: '',
                formName: 'SocGridSampling',
                visibleDeps: { },
                enableDeps: { is: { and : ['withinRange', 'geolocation']} },
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        sampleDepthCm: 30,
                        sampleDiameterCm: 3,
                    },
                    requiredPictures: 0
                }
            }
        ]
    },
    dapSample: {
        actionRangeMts: 20,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                dialog: '',
                formName: 'DapSample',
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        horizons: [{
                            code: '',
                            depthStart: '',
                            depthEnd: '',
                            soilTexture: '',
                            comments: ''
                        }]
                    },
                    requiredPictures: 0
                }
            }
        ]
    },
    socSiteDescription: {
        actionRangeMts: 50,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: "Comenzar",
                dialog: "",
                formName: "SocSiteDescription",
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        woodyCoverGreaterThan20Pct: '',
                        dominantSpeciesName: '',
                        secondarySpeciesName: '',
                        bareGroundPct: '',
                        litterPct: '',
                        livePlantPct: ''
                    },
                    requiredPictures: 0
                }
            },
        ]
    },
    socSampleSummary: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: "Comenzar",
                dialog: "",
                formName: "SocSampleSummary",
                visibleDeps: {},
                enableDeps: {},
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        sampleWetWeightKg: '',
                        comments: '',
                    },
                    requiredPictures: 0
                }
            },
        ]
    },
    stmIseTask: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                dialog: 'Deberá seleccionar los indicadores de acuerdo a la matriz de ecorregión correspondiente.',
                formName: 'StmIseForm',
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: true,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        totalIse: 0,
                    },
                    requiredPictures: 0
                }
            },
        ]
    },
    stmIse2Task: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                dialog: 'Deberá seleccionar los indicadores de acuerdo a la matriz de ecorregión correspondiente.',
                formName: 'StmIse2Form',
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: true,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        totalIse: 0,
                    },
                    requiredPictures: 0
                }
            },
        ]
    }, // TODO: Add task config
    stmForagePictures: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                formName: 'StmForagePictures',
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        dryMatterPerHectare: '',
                        dryMatterPerAnimalDayM2: '',
                        forageQuality: 1,
                        forageUsePattern: '',
                        forageIntensity: ''
                    },
                    requiredPictures: 0
                }
            },
        ]
    },
    ltmSitePictures: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                formName: 'LtmSitePictures',
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: false,
                alwaysVisible: true,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                }
            }
        ]
    },
    ltmBiodiversityT1: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                formName: 'LtmBiodiversity',
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        species1to50: [{ speciesName: '', score: '' }],
                        species51to100: [{ speciesName: '', score: '' }]
                    }
                }
            },
        ]
    },
    ltmBiodiversityT2: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                formName: 'LtmBiodiversity',
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        species1to50: [{ speciesName: '', score: '' }],
                        species51to100: [{ speciesName: '', score: '' }]
                    }
                }
            },
        ]
    },
    ltmInfiltrometer: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                formName: 'LtmInfiltrometer',
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        initialSaturationVolume: 450,
                        initialSaturation: false,
                        infiltrationTestVolume: 450,
                        infiltrationTime: '',
                        remainingWaterDepth: ''
                    }
                }
            }
        ]
    },
    ltmGenerateGrid: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: generateGridDefaultActions
    },
    ltmBiodiversityFlexibleAreaStep1: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                formName: 'LtmBiodiversityFlexibleAreaStep1',
                visibleDeps: { is: [], not: ['taskStarted'] },
                enableDeps: { is: ['withinRange', 'rangeUnlocked'], not: ['taskStarted'] },
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        speciesFlexibleArea: [{ speciesName: '', areaCovered: 0, amountOfIndividuals: 0 }],
                    },
                    requiredPictures: 0
                }
            }
        ]
    },
    ltmBiodiversityFlexibleAreaStep2: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                formName: 'LtmBiodiversityFlexibleAreaStep2',
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        speciesExtendedArea: [{ speciesName: '', areaCovered: 0, shortestDistanceToT2: 0 }]
                    },
                    requiredPictures: 0
                }
            }

        ]
    },
    ltmBiodiversityT3: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                formName: 'LtmBiodiversityT3',
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {},
                    requiredPictures: 0
                }
            }
        ]
    },
    ltmIse: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                formName: 'StmIseForm',
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                }
            }
        ]
    },
    ltmSoilProfile: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Comenzar',
                formName: 'LtmSoilProfile',
                visibleDeps: { },
                enableDeps: { is: { and: ['withinRange', 'geolocation']}},
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    defaultValues: {
                        horizons: [{
                            code: '',
                            depthStart: 0,
                            depthEnd: 0,
                            soilTexture: '',
                            structureType: '',
                            structureSize: '',
                            structureGrade: '',
                            colorWet: '',
                            colorDry: '',
                            rootsAmount: '',
                            gravelsAmount: '',
                            comments: ''
                        }],
                    }
                }
            }
        ]
    },
    createValidationSite: {
        actionRangeMts: null,
        allowRangeOverride: true,
        actions: [
            {
                action: startGenericTask,
                color: 'primary',
                name: 'Crear sitio',
                formName: 'CreateValidationSite',
                visibleDeps: { },
                enableDeps: { },
                needConfirmation: false,
                completeTaskAfterSubmit: true,
                formConfig: {
                    fullHeight: true,
                    formSubmitAction: 'createValidationSite',
                }
            }
        ]
    }
}