/**
 * Sistema de capas para zIndex en la aplicación
 *
 * Este sistema define valores estandarizados para zIndex que deben utilizarse
 * en todos los componentes de la aplicación para garantizar una jerarquía visual
 * coherente y evitar conflictos de superposición.
 *
 * NOTA: Leaflet usa valores altos por defecto (200-700) para sus capas de mapa.
 * Los elementos que necesiten mostrarse por encima del mapa deben usar el rango mapOverlay o superior.
 */

const zIndexLayers = {
  base: 1,

  content: 10,

  controls: 100,
  navControls: 25,

  underMap: 150,

  mapOverlay: 800,
  mapControls: 850,

  floating: 900,
  tooltip: 920,

  overlay: 950,
  loading: 980,

  drawer: 1000,
  modal: 1050,

  maximum: 1100,
  notification: 1150,
  critical: 1199,
};

export default zIndexLayers;
