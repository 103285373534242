import * as turf from "@turf/turf";
import seedrandom from "seedrandom";

class RNGGenerator {
  constructor(seed) {
    this.index = 1;
    this.rng = seedrandom(seed);
  }

  getIndex() {
    return this.index;
  }

  forward(newIndex) {
    for (let i = this.index; i < newIndex; i++) {
      this.next();
    }
  }

  next() {
    this.index++;
    return this.rng();
  }

  reset() {
    this.index = 0;
    this.rng = seedrandom(this.seed);
  }
}

const splitStrataIntoPolygons = (strata) => {
  const strataArea = turf.area(strata);
  if (strataArea === 0) {
    return [];
  }
  const minimumPercentageOfStrata = 0;
  const minimumAreaOfStrata = 0;

  const stratasSplitIntoPolygons = [];

  if (strata.geometry.type.toLowerCase() === "polygon") {
    const polygon = {
      feature: strata,
      area: turf.area(strata),
      index: 0,
    };
    stratasSplitIntoPolygons.push(polygon);
  }

  if (strata.geometry.type.toLowerCase() === "multipolygon") {
    strata.geometry.coordinates.forEach((coordinates, index) => {
      const polygon = {
        feature: turf.polygon(coordinates),
        area: turf.area(turf.polygon(coordinates)),
        index,
      };
      const percentageOfStrata = (polygon.area / strataArea) * 100;

      if (percentageOfStrata >= minimumPercentageOfStrata && polygon.area >= minimumAreaOfStrata) {
        stratasSplitIntoPolygons.push(polygon);
      }
    });
  }

  return stratasSplitIntoPolygons;
};

const randomize = ({ samples, samplingArea, points, rng, auditReport, index }) => {
  const stratasSplitIntoPolygons = splitStrataIntoPolygons(samplingArea.toGeoJSON);

  if (!stratasSplitIntoPolygons?.length) {
    return points;
  }

  while (points.length < samples) {
    const currentIteration = rng.getIndex();
    const randomValueForSubpolygon = rng.next();
    const subpolygonStrataIndex = stratasSplitIntoPolygons.length > 1 ? Math.floor(randomValueForSubpolygon * stratasSplitIntoPolygons.length) : 0;
    const subpolygonStrata = stratasSplitIntoPolygons[subpolygonStrataIndex].feature;

    const [minLng, minLat, maxLng, maxLat] = turf.bbox(subpolygonStrata);

    const randomValueForLat = rng.next();
    const randomValueForLon = rng.next();
    const lat = minLat + randomValueForLat * (maxLat - minLat);
    const lng = minLng + randomValueForLon * (maxLng - minLng);
    const point = turf.point([lng, lat]);

    const event = {
      timestamp: new Date().toISOString(),
      seed: samplingArea.name,
      randomValues: {
        subpolygon: randomValueForSubpolygon,
        lat: randomValueForLat,
        lon: randomValueForLon,
      },
      chosenSubpolygonIndex: subpolygonStrataIndex,
      bbox: { minLng, minLat, maxLng, maxLat },
      generatedPoint: [lng, lat],
      outcome: "",
      details: "",
      randomizeCounter: currentIteration / 3,
    };

    if (!turf.booleanPointInPolygon(point, subpolygonStrata)) {
      event.outcome = "rejected";
      event.details = "Point out of bounds";
      auditReport.events.push(event);
      continue;
    } else {
      event.outcome = "accepted";
      event.details = "Point within polygon";
      auditReport.events.push(event);
    }

    point.properties = {
      seed: samplingArea.name,
      timestamp: event.timestamp,
      name: `${samplingArea.name}-${points.length}`,
      strata: samplingArea.name,
      randomizeCounter: currentIteration / 3,
    };

    if (index === null || index === undefined) {
      points.push(point);
    } else {
      points.splice(index, 0, point);
    }
  }
  return points;
};

export default function randomPointsInPolygonV3({ monitoringSite, samplingArea }) {
  const { randomizeCounter } = monitoringSite;

  const rng = new RNGGenerator(samplingArea.name);
  rng.forward(randomizeCounter * 3);

  const auditReport = {
    seed: samplingArea.name,
    strataName: samplingArea.name,
    startTime: new Date().toISOString(),
    events: [],
    endTime: "",
    totalPointsGenerated: 0,
  };

  const randomPoints = randomize({
    samples: 1,
    samplingArea,
    points: [],
    rng,
    auditReport,
    index: null,
  });

  auditReport.endTime = new Date().toISOString();
  auditReport.totalPointsGenerated = randomPoints.length;

  return randomPoints;
}
