//REACT
import { useEffect } from "react";

//Hook Form
import { Controller, useFormContext } from "react-hook-form";

//MUI
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { FormHelperText, OutlinedInput } from "@mui/material";

//Components
import { PhotoInput } from "../../PhotoInput/PhotoInput.jsx";
import CoordinatesInputField from "../../CoordinatesInputField/CoordinatesInputField.jsx";

export default function TaskForm({ geolocation, position, handleCameraInput, watchPosition, stopWatch, editMode, plannedLocation, pictures, monitoringSite }) {
  const { control, setValue } = useFormContext({
    defaultValues: {
      plannedLocation: monitoringSite.plannedLocation,
      actualLocation: monitoringSite.plannedLocation,
    },
  });
  const picKey = `OMITIR-SITIO-${monitoringSite.name}`;

  useEffect(() => {
    setValue("plannedLocation", monitoringSite.plannedLocation);
  }, [setValue, monitoringSite]);

  return (
    <Stack spacing={2}>
      <Controller
        control={control}
        name="plannedLocation"
        defaultValue={monitoringSite.plannedLocation}
        readOnly={true}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            name="plannedLocation"
            type={"text"}
            label="Coordenadas originales"
          />
        )}
      />
      <CoordinatesInputField
        control={control}
        editMode={editMode}
        setValue={setValue}
        position={position}
        plannedLocation={plannedLocation}
        watch={watchPosition}
        stopWatch={stopWatch}
        geolocation={geolocation}
      />
      <Controller
        control={control}
        name="comments"
        defaultValue={""}
        rules={{ required: "Campo requerido" }}
        render={({ field, fieldState }) => (
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel htmlFor="comments">Comentarios</InputLabel>
            <OutlinedInput
              id={"comments"}
              {...field}
              error={fieldState.error ? true : false}
              label="Comentarios"
              onChange={(e) => field.onChange(e)}
            />
            <FormHelperText color={fieldState.error ? "error" : "inherit"}>
              {fieldState.error ? fieldState.error.message : "Motivo por el cual se omite la confirmación"}
            </FormHelperText>
          </FormControl>
        )}
      />
      <Stack
        spacing={1}
        display="flex"
        flexWrap={"wrap"}
        direction="column"
        alignItems="center"
        justifyContent="space-around"
      >
        <Typography
          textAlign="center"
          variant="caption"
        >
          Fotografía del dispositivo GPS con las coordenadas del sitio (opcional)
        </Typography>
        <PhotoInput
          width={150}
          height={150}
          handleCameraFile={handleCameraInput}
          picture={pictures.find((pic) => pic.key === picKey + "-1")}
          pictureKey={pictures.find((pic) => pic.key === picKey)?.key || picKey + "-1"}
          pictureIndex={pictures.findIndex((pic) => pic.key === picKey + "-1") >= 0 ? pictures.findIndex((pic) => pic.key === picKey + "-1") : 0}
          pictureId={pictures.find((pic) => pic.key === picKey + "-1")?.id || null}
        />
      </Stack>
    </Stack>
  );
}
