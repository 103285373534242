//REACT
import { useContext } from "react";

//Context
import TaskContext from "../../../context/taskContext.js";

//MUI
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

//Components
import { PhotoInput } from "../../PhotoInput/PhotoInput.jsx";

export default function TaskForm({ geolocation, position, handleCameraInput, watchPosition, stopWatch, editMode, plannedLocation, pictures }) {
  const { task } = useContext(TaskContext);

  return (
    <Stack spacing={2}>
      <Grid
        component="fieldset"
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        container
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Stack
            direction="column"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <Stack
              textAlign="center"
              p={2}
              spacing={1}
            >
              <Typography variant="bodyBold">FOTO 1</Typography>
              <Typography
                textAlign={"center"}
                variant="caption"
              >
                Tomar la foto desde la <b>ESTACA 1</b> hacia la instalación del monitor. La foto debe ser tomada a <b>2.5m de altura</b>
              </Typography>
            </Stack>
            <PhotoInput
              width={200}
              height={150}
              handleCameraFile={handleCameraInput}
              picture={pictures?.find((pic) => pic.key === task.key + "-stake-1")}
              pictureKey={task.key + "-stake-1"}
              pictureIndex={
                pictures.findIndex((pic) => pic.key === task.key + "-stake-1") >= 0 ? pictures.findIndex((pic) => pic.key === task.key + "-stake-1") : 0
              }
              pictureId={pictures.find((pic) => pic.key === task.key + "-stake-1")?.id || null}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
        >
          <Stack
            direction="column"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            spacing={1}
            p={2}
          >
            <Typography variant="bodyBold">FOTO 2</Typography>
            <Typography
              textAlign={"center"}
              variant="caption"
            >
              Mitad derecha del área de fotografía (Entre estacas 2 y 3){" "}
              <b>
                alejarse 1m de la cuerda, y tomar la foto 45° hacia el suelo. Utilizar el marco de 0.25m<sup>2</sup>
              </b>
            </Typography>
            <PhotoInput
              width={200}
              height={150}
              handleCameraFile={handleCameraInput}
              picture={pictures?.find((pic) => pic.key === task.key + "-right-half")}
              pictureKey={task.key + "-right-half"}
              pictureIndex={
                pictures.findIndex((pic) => pic.key === task.key + "-right-half") >= 0 ? pictures.findIndex((pic) => pic.key === task.key + "-right-half") : 1
              }
              pictureId={pictures.find((pic) => pic.key === task.key + "-right-half")?.id || null}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
        >
          <Stack
            direction="column"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            spacing={1}
            p={2}
          >
            <Typography variant="bodyBold">FOTO 3</Typography>
            <Typography
              textAlign={"center"}
              variant="caption"
            >
              Mitad izquierda del área de fotografía (Entre estacas 3 y 4){" "}
              <b>
                alejarse 1m de la cuerda, y tomar la foto 45° hacia el suelo. Utilizar el marco de 0.25m<sup>2</sup>
              </b>
            </Typography>
            <PhotoInput
              width={200}
              height={150}
              handleCameraFile={handleCameraInput}
              picture={pictures?.find((pic) => pic.key === task.key + "-left-half")}
              pictureKey={task.key + "-left-half"}
              pictureIndex={
                pictures.findIndex((pic) => pic.key === task.key + "-left-half") >= 0 ? pictures.findIndex((pic) => pic.key === task.key + "-left-half") : 2
              }
              pictureId={pictures.find((pic) => pic.key === task.key + "-left-half")?.id || null}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
        >
          <Stack
            direction="column"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            spacing={1}
            pt={2}
          >
            <Typography variant="bodyBold">FOTO 4</Typography>
            <Typography
              textAlign={"center"}
              variant="caption"
            >
              Tomar la foto desde la{" "}
              <b>
                ESTACA 5 90° hacia el suelo. Utilizar el marco de 0.25m<sup>2</sup>
              </b>
            </Typography>
            <PhotoInput
              width={200}
              height={150}
              handleCameraFile={handleCameraInput}
              picture={pictures?.find((pic) => pic.key === task.key + "-photo-4")}
              pictureKey={task.key + "-photo-4"}
              pictureIndex={
                pictures.findIndex((pic) => pic.key === task.key + "-photo-4") >= 0 ? pictures.findIndex((pic) => pic.key === task.key + "-photo-4") : 3
              }
              pictureId={pictures.find((pic) => pic.key === task.key + "-photo-4")?.id || null}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
        >
          <Stack
            direction="column"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            spacing={1}
            p={2}
          >
            <Typography variant="bodyBold">FOTO 5</Typography>
            <Typography
              textAlign={"center"}
              variant="caption"
            >
              Tomar la foto desde la{" "}
              <b>
                ESTACA 6 90° hacia el suelo. Utilizar el marco de 0.25m<sup>2</sup>
              </b>
            </Typography>
            <PhotoInput
              width={200}
              height={150}
              handleCameraFile={handleCameraInput}
              picture={pictures?.find((pic) => pic.key === task.key + "-photo-5")}
              pictureKey={task.key + "-photo-5"}
              pictureIndex={
                pictures.findIndex((pic) => pic.key === task.key + "-photo-5") >= 0 ? pictures.findIndex((pic) => pic.key === task.key + "-photo-5") : 4
              }
              pictureId={pictures.find((pic) => pic.key === task.key + "-photo-5")?.id || null}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
        >
          <Stack
            direction="column"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            spacing={1}
            p={2}
          >
            <Typography variant="bodyBold">FOTO 6</Typography>
            <Typography
              textAlign={"center"}
              variant="caption"
            >
              Tomar la foto desde la{" "}
              <b>
                ESTACA 7 90° hacia el suelo. Utilizar el marco de 0.25m<sup>2</sup>
              </b>
            </Typography>
            <PhotoInput
              width={200}
              height={150}
              handleCameraFile={handleCameraInput}
              picture={pictures?.find((pic) => pic.key === task.key + "-photo-6")}
              pictureKey={task.key + "-photo-6"}
              pictureIndex={
                pictures.findIndex((pic) => pic.key === task.key + "-photo-6") >= 0 ? pictures.findIndex((pic) => pic.key === task.key + "-photo-6") : 5
              }
              pictureId={pictures.find((pic) => pic.key === task.key + "-photo-6")?.id || null}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
