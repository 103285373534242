import { Controller } from "react-hook-form";
import { useContext } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { PhotoInput } from "../../PhotoInput/PhotoInput.jsx";
import TaskContext from "../../../context/taskContext.js";
import TaskFormContext from "../../../context/TaskFormContext.js";

const SpeciesDetailsModal = ({ open, onClose, control, fieldName, fieldArrayName, index, picture, saveSpeciesDetails }) => {
  const { task } = useContext(TaskContext);
  const { handleCameraFile } = useContext(TaskFormContext);

  return (
    <Dialog
      sx={{ zIndex: 9999 }}
      open={open}
      onClose={onClose}
      fullWidth
      disableRestoreFocus
      aria-labelledby="species-detail-dialog-title"
    >
      <DialogTitle id="species-detail-dialog-title">Detalles de la especie</DialogTitle>
      <DialogContent>
        <Stack
          marginTop={1}
          spacing={4}
        >
          <Controller
            control={control}
            name={fieldArrayName ? `${fieldArrayName}.${index}.speciesDetails` : `${fieldName}-speciesDetails`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                autoFocus
                margin="dense"
                placeholder="Agregue detalles que ayuden a identificar la especie."
                type="text"
                fullWidth
                required
                multiline
                rows={3}
                onChange={field.onChange}
              />
            )}
          />
          <PhotoInput
            picture={picture}
            pictureId={picture?.id || null}
            pictureKey={picture?.key || (fieldArrayName ? `${task.key}-${fieldArrayName}-speciesDetails-${index}` : `${task.key}-${fieldName}-speciesDetails`)}
            height={120}
            width={120}
            handleCameraFile={handleCameraFile}
            pictureIndex={index}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonGroup
          fullWidth
          size="small"
        >
          <Button
            onClick={onClose}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={saveSpeciesDetails}
            color="primary"
            variant="contained"
          >
            Guardar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default SpeciesDetailsModal;
