import React from "react";

const AppContext = React.createContext({
  currentUser: {},
  setCurrentUser: () => {},
  currentPage: "Inicio",
  setCurrentPage: () => {},
  appBarTitle: "Inicio",
  setAppBarTitle: () => {},
  navBackTarget: "",
  setNavBackTarget: () => {},
  showFilters: false,
  filtersConfig: {},
  setShowFilters: () => {},
  setFiltersConfig: () => {},
  setActiveFilter: () => {},
  activeFilter: { mainFilter: 999, secondaryFilter: 999 },
  alert: {},
  setAlert: () => {},
  currentEvent: {},
  setCurrentEvent: () => {},
  notify: {},
  setNotify: () => {},
  currentProgramConfig: {},
  setCurrentPogramConfig: () => {},
  setStorageData: () => {},
  storageData: {},
  setStorageFreeze: () => {},
  storageFreeze: false,
  locale: "es_AR",
  setLocale: () => {},
});
export const AppContextProvider = AppContext.Provider;
export default AppContext;
