//REACT
import { useState, useContext, useLayoutEffect } from "react";

import AppContext from "../../context/appContext";

//MUIimport PlaceIcon from '@mui/icons-material/Place';
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";

import NavigationIcon from "@mui/icons-material/Navigation";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowBack from "@mui/icons-material/ArrowBack";
import MoreVert from "@mui/icons-material/MoreVert";
import HikingIcon from "@mui/icons-material/Hiking";
import PlaceIcon from "@mui/icons-material/Place";
import SpeedIcon from "@mui/icons-material/Speed";
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
//import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

import IconButton from "@mui/material/IconButton";
import { Alert, AppBar, Divider, Toolbar } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Marquee from "react-fast-marquee";
import zIndexLayers from "../../constants/zIndexLayers.js";

const DirectionIcon = ({ angle }) => {
  let str = "-";
  if (angle > 20 && angle <= 67) str = "NE";
  if (angle > 67 && angle <= 113) str = "E";
  if (angle > 113 && angle <= 157) str = "SE";
  if (angle > 157 && angle <= 180) str = "S";
  if (angle > -180 && angle <= -160) str = "S";
  if (angle > -160 && angle <= -113) str = "SW";
  if (angle > -113 && angle <= -67) str = "W";
  if (angle > -67 && angle <= -20) str = "NW";
  if (angle > -20 && angle <= 20) str = "N";
  if (isNaN(angle) || !angle) str = "";
  return <Typography variant="caption">{str}</Typography>;
};

export default function NavDisplay(props) {
  const { setNotify } = useContext(AppContext);
  const [distance, setDistance] = useState();
  const [distanceUnit, setDistanceUnit] = useState("m");
  const [directions, setDirections] = useState(null);
  const [showDirections, setShowDirections] = useState(true);
  const showCoords = useMediaQuery("(min-width:250px)");
  const bigNumbers = useMediaQuery("(min-width:340px)");

  const navBack = () => {
    props.navBack();
  };

  const getRange = () => {
    const chipStyle = { borderRadius: "5px 5px", width: "60px", height: "18px" };
    //const gpsOffStyle = { borderRadius: '5px 5px', height: '18px' }

    /*if (!props.geolocation) {
            return (<Chip sx={gpsOffStyle} size='small' color={'warning'} label={'GPS OFF'} />)
        }*/
    if (props.actionRangeMts == null) {
      return (
        <Stack
          direction={"row"}
          spacing={1}
        >
          <Typography variant="caption">RANGO</Typography>
          <Chip
            sx={chipStyle}
            size="small"
            color={"success"}
            label={"LIBRE"}
          />
        </Stack>
      );
    }
    if (props.withinRange) {
      return (
        <Stack
          direction={"row"}
          spacing={1}
        >
          <Typography variant="caption">RANGO</Typography>
          <Chip
            sx={chipStyle}
            size="small"
            color={"success"}
            label={`${props.actionRangeMts}m`}
          />
          {/*props.rangeUnlocked ? <LockOpenOutlinedIcon sx={{fontSize: '16px' }} /> : <LockOutlinedIcon sx={{fontSize: '16px'}}/>*/}
        </Stack>
      );
    }

    if (props.actionRangeMts && props.actionRangeMts != null) {
      return (
        <Stack
          direction={"row"}
          spacing={1}
        >
          <Typography variant="caption">RANGO</Typography>
          {/*<Chip sx={chipStyle} size='small' color={props.rangeUnlocked ? 'warning' : 'error'} label={`${props.actionRangeMts}m`} />*/}
          <Chip
            sx={chipStyle}
            size="small"
            color={"error"}
            label={`${props.actionRangeMts}m`}
          />

          {/*props.rangeUnlocked ? <LockOpenOutlinedIcon sx={{fontSize: '16px'}} /> : <LockOutlinedIcon sx={{fontSize: '16px'}} />*/}
        </Stack>
      );
    }
  };

  const handleCopyToClipBoard = () => {
    setNotify({ show: true, severity: "success", message: "Coordenadas copiadas" });
  };

  useLayoutEffect(() => {
    if (!isNaN(props.distance)) {
      if (props.distance > 999) {
        setDistance((props.distance / 1000).toFixed(1));
        setDistanceUnit("km");
      } else {
        setDistance(props.distance.toFixed(1));
        setDistanceUnit("m");
      }
    } else {
      setDistance("-");
      setDistanceUnit("m");
    }
  }, [props.distance, props.bearing]);

  useLayoutEffect(() => {
    if (props.heading === null) {
      setDirections({ message: `Comienza a moverte en dirección al destino`, icon: <HikingIcon /> });
    }
    if (props.distance < 50) {
      setDirections({ message: "Estás acercándote al sitio. Circula lentamente", icon: <SpeedIcon /> });
    }
    if (props.distance > 50 && props.heading) {
      setDirections({ message: "Utiliza los indicadores de RUMBO y DESTINO para guiarte.", icon: <NavigationIcon /> });
    }
  }, [props.heading, props.distance]);

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: "rgb(63, 57, 56)",
          top: 0,
          zIndex: zIndexLayers.mapOverlay,
        }}
        position="sticky"
      >
        <Toolbar disableGutters>
          <Stack
            flex
            spacing={1}
            alignItems="center"
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              onClick={() => navBack()}
            >
              <ArrowBack fontSize="35px" />
              {/*  */}
            </IconButton>
            <Typography
              sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
              variant="h6"
            >
              {props.taskName}
            </Typography>
            <IconButton
              size="medium"
              disabled
              edge="end"
            >
              <MoreVert sx={{ color: "rgb(63, 57, 56)", fontSize: "35px" }} />
            </IconButton>
          </Stack>
        </Toolbar>
        <Stack
          flex
          height={"56px"}
          mr={2}
          ml={2}
          mb={1.5}
          spacing={1}
          alignItems={"center"}
          justifyContent={"space-between"}
          direction={"row"}
        >
          <Stack
            flex
            minHeight="56px"
            direction="column"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              flex
              spacing={1}
              alignItems="center"
              direction={"row"}
              justifyContent={"space-between"}
            >
              <HikingIcon sx={{ fontSize: "20px" }} />
              <Typography
                sx={{ overflow: "hidden", textOverflow: "ellipsis", wordWrap: "break-word" }}
                variant={bigNumbers ? "gpsNavDistanceBig" : "gpsNavDistanceSmall"}
              >
                {distance}
              </Typography>
              <Typography variant="caption">{distanceUnit}</Typography>
            </Stack>
            <Stack
              spacing={1}
              alignItems={"center"}
              direction={"row"}
            >
              {getRange(props.distance)}
            </Stack>
          </Stack>
          <Stack
            flex
            minHeight="56px"
            direction="column"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              flex
              spacing={1}
              alignItems="center"
              direction={"row"}
            >
              <NavigationIcon sx={{ transform: `rotate(${props.heading}deg)`, fontSize: "20px" }} />
              <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis", wordWrap: "break-word" }}>
                {props.heading ? (props.heading > 180 ? (props.heading - 360).toFixed(0) + "º" : props.heading.toFixed(0) + "º") : "-"}
              </Typography>
              <DirectionIcon angle={props.heading} />
            </Stack>
            <Typography variant="caption">RUMBO</Typography>
          </Stack>
          <Stack
            flex
            minHeight="56px"
            direction="column"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              flex
              spacing={1}
              alignItems="center"
              direction={"row"}
            >
              <PlaceIcon sx={{ fontSize: "20px" }} />
              <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis", wordWrap: "break-word" }}>
                {props.bearing ? props.bearing.toFixed(0) + "º" : "-"}
              </Typography>
              <DirectionIcon angle={props.bearing} />
            </Stack>
            <Typography variant="caption">DESTINO</Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          mr={2}
          ml={2}
          mb={1}
          mt={1}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box
            flex
            alignItems={"center"}
            justifyContent={"center"}
            maxWidth={"50vw"}
          >
            <Typography
              component="div"
              variant="caption"
            >
              SITIO
            </Typography>
            <Marquee loop={1}>
              <Typography variant="body2">{props.monitoringSite ? props.monitoringSite.name : props.destinationName}</Typography>
            </Marquee>
          </Box>
          {showCoords && props.destination && (
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
            >
              <CopyToClipboard
                text={`${props.destination.lat},${props.destination.lng}` || ""}
                onCopy={() => handleCopyToClipBoard()}
              >
                <ContentCopyIcon sx={{ fontSize: "18px" }} />
              </CopyToClipboard>
              <Stack
                width="100%"
                flex
                flexGrow={0}
                direction={"column"}
                flexWrap={"nowrap"}
                alignItems={"end"}
                justifyContent={"center"}
                overflow={"hidden"}
              >
                <Typography
                  variant="body2"
                  sx={{ overflow: "hidden", textOverflow: "ellipsis", textWrap: "nowrap" }}
                >
                  LAT {props.destination.lat || "-"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ overflow: "hidden", textOverflow: "ellipsis", textWrap: "nowrap" }}
                >
                  LNG {props.destination.lng || "-"}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        {false && showDirections && directions && (
          <Alert
            elevation={0}
            square
            size="small"
            variant="standard"
            severity="info"
            icon={directions.icon}
            onClose={() => setShowDirections(false)}
          >
            {directions.message}
          </Alert>
        )}
      </AppBar>
    </>
  );
}
