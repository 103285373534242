//Hook Form
import { Controller, useFormContext } from "react-hook-form";

//MUI
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//Components
import CoordinatesInputField from "../../CoordinatesInputField/CoordinatesInputField.jsx";
import NumericInput from "../utils/NumericInput.jsx";
import SpeciesFieldArrayInput from "../common/SpeciesFieldArrayInput.jsx";

export default function TaskForm({ geolocation, position, watchPosition, stopWatch, editMode, plannedLocation }) {
  const { control, setValue } = useFormContext();

  return (
    <Stack spacing={2}>
      <CoordinatesInputField
        control={control}
        editMode={editMode}
        setValue={setValue}
        position={position}
        plannedLocation={plannedLocation}
        watch={watchPosition}
        stopWatch={stopWatch}
        geolocation={geolocation}
      />
      <Box
        pt={1}
        textAlign={"center"}
      >
        <Typography>
          <b>Lectura área extendida</b>
        </Typography>
        <Typography>Ingrese debajo, para cada especie, el área cubierta y la distancia a transecta #2 del individuo mas cercano.</Typography>
      </Box>
      <SpeciesFieldArrayInput
        fieldArrayName="speciesExtendedArea"
        withFunctionalGroup={true}
      >
        {(item, index) => (
          <Stack
            direction={"row"}
            width={"100%"}
            spacing={1}
          >
            <Controller
              rules={{ required: true }}
              key={`speciesExtendedArea.${item.id}.shortestDistanceToT2`}
              control={control}
              name={`speciesExtendedArea.${index}.shortestDistanceToT2`}
              render={({ field, fieldState }) => (
                <NumericInput
                  field={field}
                  fieldState={fieldState}
                  label="Distancia T2"
                  min={0}
                  max={null}
                  unit={"m"}
                />
              )}
            />
            <Controller
              rules={{ required: true }}
              key={`speciesExtendedArea.${item.id}.areaCovered`}
              control={control}
              name={`speciesExtendedArea.${index}.areaCovered`}
              render={({ field, fieldState }) => (
                <NumericInput
                  field={field}
                  fieldState={fieldState}
                  label="Area cubierta"
                  min={0}
                  max={null}
                  unit={"m2"}
                />
              )}
            />
          </Stack>
        )}
      </SpeciesFieldArrayInput>
    </Stack>
  );
}
