import { useCallback } from "react";
import * as localDb from "../services/localDb/index";
import savePictures from "../services/helpers/savePictures";

export const usePictures = () => {
  const getTaskPictures = useCallback(async (taskId) => {
    let pictures = await localDb
      .getMany("pictures")
      .then((result) => result.where({ entity: "task", entityId: taskId }).toArray())
      .catch((e) => console.error("Unable to get pictures", e.message));
    return pictures || [];
  }, []);

  return {
    getTaskPictures,
    savePictures: savePictures,
  };
};
