//REACT
import { useContext, useEffect } from "react";

import { ApplicationError } from "../../models/errors/index.js";

//Hook Form
import { useForm, Controller } from "react-hook-form";

//Context
import AppContext from "../../context/appContext.js";

//localDB
import { saveSettings } from "../../services/settings/saveSettings.js";
import { getDefault } from "../../services/settings/getDefault.js";

//MUI
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ButtonGroup, Drawer, FormControl, FormHelperText } from "@mui/material";

//Components
import DrawerHeaderBar from "../DrawerHeaderBar/DrawerHeaderBar.jsx";

export default function Settings(props) {
  const { setAlert, setCurrentSettings, currentSettings, errorCatcher } = useContext(AppContext);
  const { handleSubmit, control, reset } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: false,
    shouldUnregister: true,
    shouldUseNativeValidationerrors: false,
    defaultValues: currentSettings,
  });

  const handleClose = () => {
    props.setShowSettings(false);
  };

  const handleSaveSettings = async (data) => {
    try {
      setCurrentSettings(data);
      await saveSettings(data);
      handleClose();
    } catch (error) {
      errorCatcher(new ApplicationError(error.message));
    }
  };

  const handleReset = async () => {
    try {
      let defaults = getDefault();
      setAlert({
        show: true,
        severity: "warning",
        message: "Va a restaurar los valores por defecto. ¿Desea continuar?",
        actionButtonTitle: "continuar",
        action: () => reset(defaults.configuration),
        cancelAction: () => {},
        cancelButtonTitle: "cancelar",
      });
    } catch (error) {
      errorCatcher(new ApplicationError(error.message));
    }
  };

  useEffect(() => {
    reset(currentSettings);
  }, [currentSettings, reset]);

  return (
    <Drawer
      PaperProps={{ sx: { borderRadius: "10px 10px 0 0" } }}
      anchor="bottom"
      open={props.show}
      onClose={() => handleClose()}
    >
      <Stack
        direction={"column"}
        m={1}
        height={"100vh"}
      >
        <DrawerHeaderBar
          title={"Configuración"}
          handleClose={handleClose}
        />
        <Stack
          height={"100%"}
          component="form"
          onSubmit={handleSubmit(handleSaveSettings)}
          m={2}
          spacing={2}
        >
          <Stack
            direction={"column"}
            flex={true}
            justifyContent={"space-between"}
            height={"80vh"}
          >
            <Stack
              spacing={3}
              direction={"row"}
              flex={true}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="body">Rango de confirmación de sitio</Typography>
              <Controller
                control={control}
                name="confirmSiteRangeMts"
                rules={{ required: true, min: 3, max: 20 }}
                render={({ field, fieldState }) => (
                  <FormControl>
                    <TextField
                      margin="dense"
                      variant={"standard"}
                      size="small"
                      error={fieldState.error}
                      {...field}
                      inputProps={{
                        sx: { textAlign: "center" },
                      }}
                      InputProps={{
                        endAdornment: <Typography variant="body2">m</Typography>,
                        type: "number",
                        min: 3,
                        max: 10,
                      }}
                    />
                    <FormHelperText error={fieldState.error ? true : false}>MIN: 3 - MAX: 10</FormHelperText>
                  </FormControl>
                )}
              />
            </Stack>
          </Stack>
          <Stack
            pb={3}
            direction={"row"}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            spacing={1}
          >
            <ButtonGroup>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                GUARDAR
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                CANCELAR
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleReset()}
              >
                RESET
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
}
