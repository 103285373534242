//REACT
import { useContext } from "react";

import moment from "moment";

//Hook Form
import { Controller, useFormContext } from "react-hook-form";

//Context
import TaskContext from "../../../context/taskContext.js";

//MUI
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

//Components
import { PhotoInput } from "../../PhotoInput/PhotoInput.jsx";

//localDB
import CoordinatesInputField from "../../CoordinatesInputField/CoordinatesInputField.jsx";
import NumericInput from "../utils/NumericInput.jsx";

export default function TaskForm({ geolocation, position, handleCameraInput, watchPosition, stopWatch, editMode, plannedLocation, pictures }) {
  const { task } = useContext(TaskContext);
  const { control, watch, setValue } = useFormContext();

  return (
    <Stack spacing={2}>
      <Controller
        control={control}
        name="timestamp"
        defaultValue={() => moment().format("YYYY-MM-DD HH:mm:SS")}
        render={({ field }) => (
          <TextField
            {...field}
            disabled
            name="timestamp"
            fullWidth
            label="Fecha y Hora"
            InputProps={{ readOnly: true, type: "datetime" }}
          />
        )}
      />
      <CoordinatesInputField
        control={control}
        editMode={editMode}
        setValue={setValue}
        position={position}
        plannedLocation={plannedLocation}
        watch={watchPosition}
        stopWatch={stopWatch}
        geolocation={geolocation}
      />
      <Stack
        spacing={1.5}
        component="fieldset"
        width={"100%"}
      >
        <Typography
          variant="caption"
          component="legend"
        >
          INFILTRACIÓN
        </Typography>
        <FormControlLabel
          label={"Saturación inicial completa"}
          control={
            <Controller
              control={control}
              name={`initialSaturation`}
              render={({ field, fieldState }) => (
                <Checkbox
                  name={`initialSaturation`}
                  checked={field.value}
                  label={"Saturación Inicial"}
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          }
        />
        <Controller
          control={control}
          name={"initialSaturationVolume"}
          disabled={!watch("initialSaturation")}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <NumericInput
              field={field}
              fieldState={fieldState}
              disabled={!watch("initialSaturation")}
              label="Volumen de saturación inicial"
              min={0}
              max={null}
              unit={"mm"}
            />
          )}
        />
        <Controller
          control={control}
          name={"infiltrationTestVolume"}
          disabled={!watch("initialSaturation")}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <NumericInput
              field={field}
              disabled={!watch("initialSaturation")}
              fieldState={fieldState}
              label="Volumen de prueba"
              min={0}
              max={null}
              unit={"mm"}
            />
          )}
        />
        <Controller
          control={control}
          name={"infiltrationTime"}
          disabled={!watch("initialSaturation")}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <NumericInput
              field={field}
              disabled={!watch("initialSaturation")}
              fieldState={fieldState}
              label="Tiempo de infiltración (máx 30min.)"
              min={0}
              max={30}
              unit={"min"}
            />
          )}
        />
        <Controller
          control={control}
          name="remainingWaterDepth"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <NumericInput
              field={field}
              fieldState={fieldState}
              label="Lámina de agua remanente"
              min={0}
              max={null}
              unit={"cm"}
            />
          )}
        />
      </Stack>
      <Stack
        direction={"row"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        width={"100%"}
      >
        <Stack
          spacing={2}
          direction={"column"}
          alignItems={"center"}
        >
          <Typography>Saturación inicial</Typography>
          <PhotoInput
            width={150}
            height={150}
            handleCameraFile={handleCameraInput}
            picture={pictures.find((pic) => pic.key === task.key + "-staturation")}
            pictureKey={task.key + "-staturation"}
            pictureIndex={
              pictures.findIndex((pic) => pic.key === task.key + "-staturation") >= 0 ? pictures.findIndex((pic) => pic.key === task.key + "-staturation") : 0
            }
            pictureId={pictures.find((pic) => pic.key === task.key + "-staturation")?.id || null}
          />
        </Stack>
        <Stack
          spacing={2}
          direction={"column"}
          alignItems={"center"}
        >
          <Typography>Infiltración</Typography>
          <PhotoInput
            width={150}
            height={150}
            handleCameraFile={handleCameraInput}
            picture={pictures.find((pic) => pic.key === task.key + "-infiltration")}
            pictureKey={task.key + "-infiltration"}
            pictureIndex={
              pictures.findIndex((pic) => pic.key === task.key + "-infiltration") >= 0 ? pictures.findIndex((pic) => pic.key === task.key + "-infiltration") : 1
            }
            pictureId={pictures.find((pic) => pic.key === task.key + "-infiltration")?.id || null}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
