import { v4 as uuid } from "uuid";
import moment from "moment";
import * as localDb from "../localDb";
import savePictures from "./savePictures";
import { latLngToArray } from "../geolocationScripts/latLngToArray";

export default async function completeTask({ task, setTask, userName, data = {}, pictures }) {
  try {
    if (pictures && pictures.length) {
      savePictures(userName, task.monitoringEventId, "task", task.id, pictures);
    }

    const location = data.actualLocation || task.actualLocation;
    let taskUpdate = {
      dataPayload: data,
      actualLocation: latLngToArray(location),
      deviceLocationData: data.deviceLocationData,
      taskStatusId: 2,
      updatedAt: moment.utc().format(),
      updatedBy: userName,
      _rev: uuid(),
    };
    setTask({ ...task, taskUpdate });
    await localDb.update("tasks", task.id, taskUpdate, true);
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
}
