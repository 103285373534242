import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/RemoveCircle";
import refDataByKey from "../../../services/localRefData/refDataByKey.js";
import { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import { usePictures } from "../../../hooks/useMonitoringPictures.jsx";
import TaskContext from "../../../context/taskContext.js";
import AppContext from "../../../context/appContext.js";
import { ApplicationError } from "../../../models/errors/index.js";
import { Add, RemoveRedEye } from "@mui/icons-material";
import SpeciesDetailsModal from "./SpeciesDetailsModal";
import TaskFormContext from "../../../context/TaskFormContext.js";
const SpeciesFieldArrayInput = ({ fieldArrayName, children, withFunctionalGroup = true, allowUnknownSpecies = true }) => {
  const [functionalGroups, setFunctionalGroups] = useState([]);
  const { task } = useContext(TaskContext);
  const [species, setSpecies] = useState([]);
  const [showSpeciesDetailsModal, setShowSpeciesDetailsModal] = useState({ fieldArrayName: null, index: null });
  const { deletePicture, savePictures } = usePictures();
  const { currentUser, errorCatcher } = useContext(AppContext);
  const { watch, control } = useFormContext();
  const { pictures, setPictures } = useContext(TaskFormContext);

  useEffect(() => {
    setFunctionalGroups(refDataByKey("functionalGroups"));
    setSpecies(refDataByKey("species"));
  }, []);

  const { append, remove, fields } = useFieldArray({
    control,
    name: fieldArrayName,
  });

  const handleSpeciesDetails = (fieldArrayName, index) => {
    setShowSpeciesDetailsModal({ fieldArrayName, index });
  };

  const onAddSpecies = () => {
    append({
      speciesName: { commonNames: [], scientificName: "" },
      score: 0,
      functionalGroup: {},
    });
  };

  const onRemoveSpecies = async (index) => {
    try {
      const picture = pictures.find((picture) => picture.key === `${task.key}-${fieldArrayName}-speciesDetails-${index}`);
      if (picture) {
        await deletePicture(picture.id);
        setPictures((prev) => prev.filter((prevPicture) => prevPicture.id !== picture.id));
      }
      remove(index);
    } catch (error) {
      errorCatcher(new ApplicationError(error.message));
    }
  };

  const saveSpeciesDetails = () => {
    savePictures(currentUser.username, task.monitoringEventId, "task", task.id, pictures);
    setShowSpeciesDetailsModal({ fieldArrayName: null, index: null });
  };

  return (
    <Stack
      display="flex"
      direction={"column"}
      alignItems={"center"}
      gap={2}
    >
      {species &&
        species.length > 1 &&
        fields &&
        fields.length > 0 &&
        fields.map((item, index) => (
          <Stack
            key={item.id}
            component="fieldset"
            width="100%"
          >
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              width="100%"
            >
              <Grid
                item
                xs={12}
                sm={4}
              >
                <Stack spacing={2}>
                  <Controller
                    rules={{ required: true }}
                    key={`${fieldArrayName}.${item.id}.speciesName`}
                    control={control}
                    name={`${fieldArrayName}.${index}.speciesName`}
                    render={({ field, fieldState }) => (
                      <>
                        <Autocomplete
                          {...field}
                          size="small"
                          disablePortal
                          disableClearable
                          options={[
                            allowUnknownSpecies && {
                              id: "unknown",
                              commonNames: [],
                              scientificName: "Especie faltante o no identificada",
                            },
                            ...species,
                          ]}
                          getOptionLabel={(option) => option.scientificName || ""}
                          getOptionKey={(option) => option.id}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              label="Especie"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              style={{
                                color: option.id === "unknown" ? "red" : "inherit",
                                fontWeight: option.id === "unknown" ? "bold" : "normal",
                              }}
                            >
                              {`${option?.scientificName}${option?.commonNames?.[0] ? ` (${option.commonNames[0]})` : ""}`}
                            </li>
                          )}
                          onBlur={field.onBlur}
                          onChange={(event, newValue) => {
                            if (!newValue) {
                              field.onChange(field.value);
                              return;
                            }
                            if (newValue.id === "unknown") {
                              setShowSpeciesDetailsModal({ fieldArrayName, index });
                            }
                            field.onChange(newValue);
                          }}
                        />
                        <Button
                          size="small"
                          variant="text"
                          color="primary"
                          sx={{
                            fontSize: "0.75rem",
                            marginTop: 8,
                          }}
                          startIcon={watch(`${fieldArrayName}.${index}.speciesDetails`) ? <RemoveRedEye /> : <Add />}
                          onClick={() => handleSpeciesDetails(fieldArrayName, index)}
                        >
                          {watch(`${fieldArrayName}.${index}.speciesDetails`) ? "VER DETALLES" : "AGREGAR DETALLES"}
                        </Button>
                      </>
                    )}
                  />

                  {withFunctionalGroup && (
                    <Controller
                      rules={{ required: true }}
                      defaultValue={""}
                      key={`${fieldArrayName}.${item.id}.functionalGroup`}
                      control={control}
                      name={`${fieldArrayName}.${index}.functionalGroup`}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          size="small"
                          disablePortal
                          disableClearable
                          autoSelect
                          options={functionalGroups}
                          getOptionLabel={(option) => option["es_AR"] || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Grupo Funcional"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                            />
                          )}
                          onChange={(_event, value) => {
                            field.onChange(value);
                          }}
                        />
                      )}
                    />
                  )}
                </Stack>
              </Grid>
              <Grid
                item
                xs={10}
                sm={7}
              >
                {children(item, index)}
              </Grid>
              <Grid
                item
                xs={2}
                sm={1}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <IconButton
                  disabled={index === 0}
                  size="small"
                  sx={{ padding: "4px" }}
                  color="error"
                  onClick={() => onRemoveSpecies(index)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            <SpeciesDetailsModal
              key={`${fieldArrayName}-${index}`}
              picture={pictures.find((picture) => picture.key === `${task.key}-${fieldArrayName}-speciesDetails-${index}`)}
              control={control}
              fieldArrayName={fieldArrayName}
              index={index}
              saveSpeciesDetails={saveSpeciesDetails}
              open={showSpeciesDetailsModal.fieldArrayName === fieldArrayName && showSpeciesDetailsModal.index === index}
              onClose={() => setShowSpeciesDetailsModal({ fieldArrayName: null, index: null })}
            />
          </Stack>
        ))}
      <Button
        color="primary"
        variant="outlined"
        size="small"
        sx={{
          fontSize: "0.75rem",
          padding: "4px 8px",
        }}
        onClick={onAddSpecies}
      >
        + AGREGAR ESPECIE
      </Button>
    </Stack>
  );
};

export default SpeciesFieldArrayInput;
