import { Controller, useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useState, useContext } from "react";
import Button from "@mui/material/Button";
import { Add, RemoveRedEye } from "@mui/icons-material";
import refDataByKey from "../../../services/localRefData/refDataByKey";
import SpeciesDetailsModal from "./SpeciesDetailsModal";
import { usePictures } from "../../../hooks/useMonitoringPictures.jsx";
import TaskContext from "../../../context/taskContext.js";
import AppContext from "../../../context/appContext.js";
import { ApplicationError } from "../../../models/errors/index.js";
import TaskFormContext from "../../../context/TaskFormContext.js";
import { IconButton, InputAdornment } from "@mui/material";

export const SpeciesSingleInput = ({ fieldName, label, withFunctionalGroup = true, allowUnknownSpecies = true }) => {
  const [functionalGroups, setFunctionalGroups] = useState([]);
  const [species, setSpecies] = useState([]);
  const [showSpeciesDetailsModal, setShowSpeciesDetailsModal] = useState(false);
  const { task } = useContext(TaskContext);
  const { currentUser, errorCatcher } = useContext(AppContext);
  const { savePictures } = usePictures();
  const { control, watch } = useFormContext();
  const { pictures } = useContext(TaskFormContext);

  useEffect(() => {
    setFunctionalGroups(refDataByKey("functionalGroups"));
    setSpecies(refDataByKey("species"));
  }, []);

  const handleSpeciesDetails = () => {
    setShowSpeciesDetailsModal(true);
  };

  const saveSpeciesDetails = () => {
    try {
      savePictures(currentUser.username, task.monitoringEventId, "task", task.id, pictures);
      setShowSpeciesDetailsModal(false);
    } catch (error) {
      errorCatcher(new ApplicationError(error.message));
    }
  };

  return (
    <Stack
      display="flex"
      direction={"column"}
      spacing={1}
    >
      <Stack
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        direction={"column"}
        spacing={1}
        component={"fieldset"}
      >
        <Controller
          rules={{ required: true }}
          control={control}
          name={fieldName}
          render={({ field, fieldState }) => (
            <>
              <Autocomplete
                {...field}
                fullWidth
                disablePortal
                options={[
                  allowUnknownSpecies && {
                    id: "unknown",
                    commonNames: [],
                    scientificName: "Especie faltante o no identificada",
                  },
                  ...species,
                ]}
                getOptionLabel={(option) => option.scientificName || ""}
                getOptionKey={(option) => option.id}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Especie"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleSpeciesDetails}>{watch(`${fieldName}-speciesDetails`) ? <RemoveRedEye /> : <Add />}</IconButton>
                      </InputAdornment>
                    }
                  />
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      color: option.id === "unknown" ? "red" : "inherit",
                      fontWeight: option.id === "unknown" ? "bold" : "normal",
                    }}
                  >
                    {`${option?.scientificName}${option?.commonNames?.[0] ? ` (${option.commonNames[0]})` : ""}`}
                  </li>
                )}
                onBlur={field.onBlur}
                onChange={(event, newValue) => {
                  if (!newValue) {
                    field.onChange(field.value);
                    return;
                  }
                  if (newValue.id === "unknown") {
                    setShowSpeciesDetailsModal(true);
                  }
                  field.onChange(newValue);
                }}
              />
              <Button
                size="small"
                variant="text"
                color="primary"
                sx={{
                  fontSize: "0.75rem",
                  marginTop: 1,
                }}
                startIcon={watch(`${fieldName}-speciesDetails`) ? <RemoveRedEye /> : <Add />}
                onClick={handleSpeciesDetails}
              >
                {watch(`${fieldName}-speciesDetails`) ? "VER DETALLES" : "AGREGAR DETALLES"}
              </Button>
            </>
          )}
        />
        {withFunctionalGroup && (
          <Controller
            rules={{ required: true }}
            key={`${fieldName}-functionalGroup`}
            control={control}
            name={`${fieldName}-functionalGroup`}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                fullWidth
                size="small"
                disablePortal
                disableClearable
                autoSelect
                options={functionalGroups}
                getOptionLabel={(option) => option["es_AR"] || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Grupo Funcional"
                    error={fieldState.error ? true : false}
                  />
                )}
                onChange={(_event, value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
        )}
      </Stack>

      <SpeciesDetailsModal
        picture={pictures.find((picture) => picture.key === `${task.key}-${fieldName}-speciesDetails`)}
        control={control}
        fieldName={fieldName}
        index={0}
        saveSpeciesDetails={saveSpeciesDetails}
        open={showSpeciesDetailsModal}
        onClose={() => setShowSpeciesDetailsModal(false)}
      />
    </Stack>
  );
};

export default SpeciesSingleInput;
