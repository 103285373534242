import ManualGridLocation from "./common/ManualGridLocation";
import OverrideSiteConfirmation from "./common/OverrideSiteConfirmation";
import RejectSite from "./common/RejectSite";
import CreateValidationSite from "./fbc/CreateValidationSite";
import LtmBiodiversity from "./ltm/LtmBiodiversity";
import LtmBiodiversityFlexibleAreaStep1 from "./ltm/LtmBiodiversityFlexibleAreaStep1";
import LtmBiodiversityFlexibleAreaStep2 from "./ltm/LtmBiodiversityFlexibleAreaStep2";
import LtmBiodiversityT3 from "./ltm/LtmBiodiversityT3";
import LtmInfiltrometer from "./ltm/LtmInfiltrometer";
import LtmSitePictures from "./ltm/LtmSitePictures";
import LtmSoilProfile from "./ltm/LtmSoilProfile";
import DapSample from "./soc/DapSample";
import SocGridSampling from "./soc/SocGridSampling";
import SocSampleSummary from "./soc/SocSampleSummary";
import SocSiteDescription from "./soc/SocSiteDescription";
import StmForagePictures from "./stm/StmForagePictures";
import StmIseForm from "./stm/StmIseForm";
import StmIse2Form from "./stm/StmIse2Form";

export const taskForms = {
  RejectSite,
  SocGridSampling,
  SocSampleSummary,
  SocSiteDescription,
  DapSample,
  StmForagePictures,
  StmIseForm,
  StmIse2Form,
  LtmSitePictures,
  LtmBiodiversity,
  LtmInfiltrometer,
  LtmBiodiversityFlexibleAreaStep1,
  LtmBiodiversityFlexibleAreaStep2,
  LtmBiodiversityT3,
  LtmSoilProfile,
  CreateValidationSite,
  OverrideSiteConfirmation,
  ManualGridLocation,
};
