//REACT
import { useState } from "react";

//Hook Form
import { Controller, useFormContext } from "react-hook-form";

//MUI
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Components
import CoordinatesInputField from "../../CoordinatesInputField/CoordinatesInputField.jsx";
import SpeciesFieldArrayInput from "../common/SpeciesFieldArrayInput.jsx";
import NumericInput from "../utils/NumericInput.jsx";

export default function TaskForm({ geolocation, position, watchPosition, stopWatch, editMode, plannedLocation }) {
  const [accordionExpanded, setAccordionExpanded] = useState([1, 2]);
  const { control, watch, setValue, formState } = useFormContext();

  const handleAccordion = (num) => {
    if (accordionExpanded.includes(num)) {
      setAccordionExpanded((s) => s.filter((a) => a !== num));
    } else {
      setAccordionExpanded((s) => [...s, num]);
    }
  };

  return (
    <Stack spacing={1}>
      <CoordinatesInputField
        control={control}
        editMode={editMode}
        setValue={setValue}
        position={position}
        plannedLocation={plannedLocation}
        watch={watchPosition}
        stopWatch={stopWatch}
        geolocation={geolocation}
      />
      <Box
        pt={1}
        textAlign={"center"}
      >
        <Typography variant="h6">
          <b>Tramo 1 de 2 (1 a 50)</b>
        </Typography>
        <Typography variant="body2">Ingrese en el desplegable cada especie y el puntaje total de la misma en el tramo observado</Typography>
      </Box>

      <Accordion
        expanded={"species1to50" in formState.errors ? true : accordionExpanded.includes(1)}
        onChange={() => handleAccordion(1)}
      >
        <AccordionSummary
          sx={{ backgroundColor: "primary" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body2">
            PUNTAJE: <b>{watch("species1to50")?.reduce((acc, cur) => (acc += parseFloat(cur?.score || 0)), 0)}</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SpeciesFieldArrayInput
            withFunctionalGroup={true}
            fieldArrayName="species1to50"
          >
            {(item, index) => (
              <Controller
                rules={{ required: true }}
                key={`species1to50.${item.id}.score`}
                control={control}
                name={`species1to50.${index}.score`}
                render={({ field, fieldState }) => (
                  <NumericInput
                    size={"small"}
                    field={field}
                    fieldState={fieldState}
                    label={"Pts"}
                    min={0}
                    max={50}
                  />
                )}
              />
            )}
          </SpeciesFieldArrayInput>
        </AccordionDetails>
      </Accordion>

      <Controller
        control={control}
        name={`totalScore1to50`}
        defaultValue={""}
        rules={{ min: 45, max: 55 }}
        render={({ field }) => (
          <input
            {...field}
            name="totalScore1to50"
            type="hidden"
            min={45}
            max={55}
          />
        )}
      />
      <Box
        pt={1}
        textAlign={"center"}
      >
        <Typography variant="h6">
          <b>Tramo 2 de 2 (51 a 100)</b>
        </Typography>
        <Typography variant="body2">Ingrese en el desplegable cada especie y el puntaje total de la misma en el tramo observado</Typography>
      </Box>
      <Accordion
        expanded={"species51to100" in formState.errors ? true : accordionExpanded.includes(2)}
        onChange={() => handleAccordion(2)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel2a-header"
        >
          <Typography variant="body2">
            PUNTAJE: <b>{watch("species51to100")?.reduce((acc, cur) => (acc += parseInt(cur?.score || 0)), 0)}</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 1 }}>
          <SpeciesFieldArrayInput
            withFunctionalGroup={true}
            fieldArrayName="species51to100"
            control={control}
          >
            {(item, index) => (
              <Controller
                rules={{ required: true }}
                key={`species51to100.${item.id}.score`}
                control={control}
                name={`species51to100.${index}.score`}
                render={({ field, fieldState }) => (
                  <NumericInput
                    size={"small"}
                    field={field}
                    fieldState={fieldState}
                    label={"Pts"}
                    min={0}
                    max={50}
                  />
                )}
              />
            )}
          </SpeciesFieldArrayInput>
        </AccordionDetails>
      </Accordion>
      <Controller
        control={control}
        name={`totalScore51to100`}
        defaultValue={""}
        render={({ field }) => (
          <input
            {...field}
            name="totalScore51to100"
            type="hidden"
            min={45}
            max={55}
          />
        )}
      />
    </Stack>
  );
}
