import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";

export default function NumericInput({ field, fieldState, label, unit, min, max, disabled, size = "small", sx, required, helperMessage }) {
  return (
    <FormControl
      size={size}
      variant="outlined"
      error={fieldState.error ? true : false}
    >
      <InputLabel id={field.name}>{label}</InputLabel>
      <OutlinedInput
        {...field}
        size={size}
        name={field.name}
        id={field.name}
        required={required}
        sx={sx}
        disabled={disabled}
        type="number"
        label={label}
        endAdornment={<Typography variant="caption">{unit}</Typography>}
        inputProps={{
          //inputMode: 'decimal',
          //pattern: "\d*",
          min: min,
          max: max,
          step: ".01",
        }}
        onChange={(event) => {
          if (event.target.value) {
            field.onChange(event.target.valueAsNumber);
          } else {
            field.onChange("");
          }
        }}
      />
      <FormHelperText>{fieldState.error ? fieldState.error.message : helperMessage}</FormHelperText>
    </FormControl>
  );
}
